import EventDispatcher from './libs/daijima/EventDispatcher';
import {gsap, Quart} from "gsap";

// import * as dat from "dat.gui";

export default class extends EventDispatcher {
    constructor(parent) {
        super();

        let sc = this;
        this.pack = window.tokyo.pics;
        this.parent = parent;
        this.objPath = "models/plane_logo_simplify2.obj";
        this.model;
        this.boxList = [];
        this.boxBaseScale = 1;
        this.boxRandomScale = 0.8;
        this.boxOpacity = 0.8;
        this.enterframeIgnoreCnt = 0;
        this.basePositions = [];

        // this.composer1, this.fxaaPass;

        // this.setDatGUI();

        this.loadOBJ(this.pack.PATH + this.objPath).then(function(){
            sc.loadHandler();
        });
    }

/*    setDatGUI(){
        let sc = this;

        const PROPERTY = {
            'boxBaseScale':this.boxBaseScale,
            'boxRandomScaleRange':this.boxRandomScale,
            'boxOpacity':this.boxOpacity,
        };

        this.gui = new dat.GUI();

        let changeBoxScaleHandler = function(){
            let baseScale = PROPERTY.boxBaseScale;
            let scaleRange = PROPERTY.boxRandomScaleRange;
            for( let i = 0, len = sc.boxList.length; i < len; i++ ){
                let box = sc.boxList[i];
                let scale = baseScale + Math.random() * scaleRange;
                box.scale.x = box.scale.y = box.scale.z = scale;
                box.userData.scale = scale;
            }
        }

        this.gui.add(PROPERTY, 'boxBaseScale', .5, 1.5).onChange(function(){
            changeBoxScaleHandler();
        });

        this.gui.add(PROPERTY, 'boxRandomScaleRange', 0, 2).onChange(function(){
            changeBoxScaleHandler();
        });

        this.gui.add(PROPERTY, 'boxOpacity', 0, 1).onChange(function(){
            sc.m.opacity = PROPERTY.boxOpacity;
        });
    }*/


    loadOBJ(path) {
        let sc = this;
        let loader = new THREE.OBJLoader();

        return new Promise((resolve) => {
            loader.load(
                path,
                function ( object ) {
                    sc.model = object;
                    return resolve();
                },
                function ( xhr ) {
                    // trace( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                },
                function ( error ) {
                    console.error( 'An error happened at BoxPolygon' );
                }
            );
        });
    }

    loadHandler(){
        this.dispatchEvent("load");
    }

    initMesh(){
        //モデリングデータ(objデータ）
        let g = this.g = this.model.children[0].geometry;
        let g_array = g.attributes.position.array;
        let g_array_length = g_array.length;
        let vertices = this.defVertices = [];

        //モデリングデータの情報からVertex化
        for( let i = 0; i < g_array_length; i+=3 ){
            let v = {};
            let tempX = g_array[i];
            v.x = (tempX > 0) ? tempX - .12: tempX + .12;
            v.y = g_array[i+1];
            v.z = g_array[i+2];
            let v_obj = new Vertex(this, i, v);
            vertices.push(v_obj);
        }

        //同じ位置のVertexはマージする
        this.mergeVertices = [];
        let mergeVertex = new MergeVertex(this, [vertices[0]]);
        this.mergeVertices.push(mergeVertex);
        for( let i = 1, len = vertices.length; i < len; i++ ){
            this.checkMerge(vertices[i]);
        }

        //マージしたObjectを初期化
        for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
            let mergeVertex = this.mergeVertices[i];
            mergeVertex.init();
        }

        //Boxを入れる箱
        this.mesh = new THREE.Group();
        this.mesh.position.z = 100;
        let boxSize = 45;
        let boxSegment = 1;

        this.m = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            // map: this.pack.mv.sandstorm.renderTarget.texture,    //直接砂嵐入れる場合
            emissive: 0x222222,
            roughness:1,
            metalness:0.5,
            flatShading: true,
            transparent: true,
            // opacity: this.boxOpacity
            opacity: 1
        });

        //各BoxをGroupの中に配置
        for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
            let p = this.mergeVertices[i].list[0].v;
            let uniqueBoxSize = boxSize;
            let g = new THREE.BoxBufferGeometry(
                uniqueBoxSize, uniqueBoxSize, uniqueBoxSize,
                boxSegment, boxSegment, boxSegment
            );

            let box = new THREE.Mesh( g, this.m );
            let scale = this.boxBaseScale + Math.random() * this.boxRandomScale;
            box.position.x = p.x * 100;
            box.position.y = p.y * 140;
            box.position.z = p.z * 140;
            box.rotation.x = Math.random() * Math.PI;
            box.rotation.y = Math.random() * Math.PI;
            box.rotation.z = Math.random() * Math.PI;
            box.scale.x = box.scale.y = box.scale.z = 1;

            box.userData.rotationXSpeed = Math.random() * .05 - .025 + Math.random() * .05 - .025;
            box.userData.rotationYSpeed = Math.random() * .05 - .025 + Math.random() * .05 - .025;
            box.userData.rotationZSpeed = Math.random() * .05 - .025 + Math.random() * .05 - .025;
            box.userData.scale = scale;
            this.mesh.add( box );
            this.boxList.push(box);
        }

        this.boxList.sort(function(a,b){
           let a_y = Math.abs(a.position.y);
           let b_y = Math.abs(b.position.y);
            if(a_y < b_y) return -1;
            if(a_y > b_y) return 1;
            return 0;
        });

        //オフスクリーンレンダリング用のシーン生成
        this.initOffscreenScene();

        //リサイズ処理実行
        this.pack.top.addResize(this, this.resizeHandler);
        this.resizeHandler();
    }

    initOffscreenScene(){
        this.width = 1400;
        this.height = 787;

        this.scene = new THREE.Scene();
        this.setViewPort();
        this.camera = new THREE.OrthographicCamera(
            this.viewPort.left,
            this.viewPort.right,
            this.viewPort.top,
            this.viewPort.bottom,
            this.viewPort.near,
            this.viewPort.far
        );
        this.camera.position.set( 0, 0, 1000 );

        this.ambient_light = new THREE.AmbientLight(0x999999, 1);
        this.scene.add(this.ambient_light);

        this.directional_light = new THREE.DirectionalLight(0xaaaaaa, 1);
        this.directional_light.position.z = 1;
        this.scene.add(this.directional_light);

        this.renderTarget = new THREE.WebGLRenderTarget( this.width, this.height,
            {
                magFilter: THREE.LinearFilter,
                minFilter: THREE.LinearFilter,
                wrapS: THREE.ClampToEdgeWrapping,
                wrapT: THREE.ClampToEdgeWrapping,
                antialias:true
            });

        this.scene.add(this.mesh);

        this.off_g = new THREE.PlaneGeometry(this.width, this.height, 2, 2);
        this.off_m = new THREE.MeshLambertMaterial({
            map: this.renderTarget.texture,
            // color: 0xffffff,
            wireframe: false,
            transparent:true,
            opacity: this.boxOpacity,
        });

        this.offMesh = new THREE.Mesh(this.off_g, this.off_m);

        //for debug(表示確認)
        // this.parent.scene.add(this.offMesh);
        // this.pack.mv.renderer.render( this.scene, this.camera, this.renderTarget );

        // this.initAntiAliasing();
    }

    initAntiAliasing(){
        //FXAA(Fast Approximate Anti-Aliasing)処理

        this.renderPass = new THREE.RenderPass( this.parent.scene, this.parent.camera );
        this.fxaaPass = new THREE.ShaderPass(THREE.FXAAShader);

        let pixelRatio = this.parent.renderer.getPixelRatio();
        this.fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( this.sw * pixelRatio );
        this.fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( this.sh * pixelRatio );

        this.composer1 = new THREE.EffectComposer( this.parent.renderer );
        this.composer1.addPass( this.renderPass );
        this.composer1.addPass( this.fxaaPass );
    }

    setViewPort() {
        this.viewPort = {};

        var w = this.width;
        var h = this.height;
        var viewSize = h;
        var aspectRatio = w / h;

        this.viewPort = {
            viewSize: viewSize,
            aspectRatio: aspectRatio,
            left: (-aspectRatio * viewSize) / 2,
            right: (aspectRatio * viewSize) / 2,
            top: viewSize / 2,
            bottom: -viewSize / 2,
            near: 0,
        }
    }

    checkMerge(target){
        let flag = false;

        for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
            if( Math.abs( target.v.x - this.mergeVertices[i].list[0].v.x ) < 0.001 &&
                Math.abs( target.v.y - this.mergeVertices[i].list[0].v.y ) < 0.001 &&
                Math.abs( target.v.z - this.mergeVertices[i].list[0].v.z ) < 0.001)
            {
                this.mergeVertices[i].list.push( target );
                flag = true;
            }
        }

        if(!flag){
            let mergeVertex = new MergeVertex(this, [target]);
            this.mergeVertices.push(mergeVertex);
        }
    }

    start(){
        // this.parent.scene.add(this.mesh);
        this.parent.scene.add(this.offMesh);
        let scale = this.scale * 1.2;
        gsap.killTweensOf(this.mesh.scale);
        gsap.to(this.mesh.scale, .5, {x:scale, y:scale, z:scale, ease:Quart.easeOut});

        for( let i = 0, len = this.boxList.length; i < len; i++ ){
            let box = this.boxList[i];
            let scale = box.userData.scale;
            let bezierScaleX = scale * 1.2 + Math.random()*.3;
            let bezierScaleY = scale * 1.2 + Math.random()*.3;
            let bezierScaleZ = scale * 1.2 + Math.random()*.3;

            gsap.killTweensOf(box.scale);
            gsap.fromTo(
                box.scale,
                .4 + Math.random()*.5,
                {x:0.001, y:0.001, z:0.001},
                {
                    delay:i *.007 + Math.random() * .2,
                    keyframes:[
                        {x:bezierScaleX, y:bezierScaleY, z:bezierScaleZ},
                        {x:scale, y:scale, z:scale}],
                    ease:Quart.easeOut
                }
            );
        }

        this.pack.top.removeEnterframe(this);
        this.pack.top.addEnterframe(this, this.enterframe);
    }


    stop(){
        let sc = this;
        gsap.killTweensOf(this.mesh.scale);
        gsap.to(this.mesh.scale, 1, {x:this.scale, y:this.scale, z:this.scale, ease:Quart.easeOut, onUpdate:function(){
            sc.enterframe();
        }, onComplete:function(){
            // sc.parent.scene.remove(sc.mesh);
            sc.parent.scene.remove(sc.offMesh);
            sc.pack.mv.resetPreviousPolygonID();
            sc.pack.top.removeEnterframe(sc);
        }});

        let len = this.boxList.length;
        let timeLag = .004;
        let totalDelay = len * timeLag + .3;

        for( let i = 0; i < len; i++ ){
            let box = this.boxList[i];
            gsap.killTweensOf(box.scale);
            gsap.to(
                box.scale,
                .3,
                {
                    delay:totalDelay - (i * timeLag + Math.random() * .2),
                    x:0.001, y:0.001, z:0.001, ease:Quart.easeOut
                }
            );
        }
    }

    enterframe(){

        if(this.enterframeIgnoreCnt % 2 === 0){
            for( let i = 0, len = this.mesh.children.length; i < len; i++ ){
                let box = this.mesh.children[i];
                box.rotation.x += box.userData.rotationXSpeed;
                box.rotation.y += box.userData.rotationYSpeed;
                box.rotation.z += box.userData.rotationZSpeed;
            }
        }

        this.pack.mv.renderer.render( this.scene, this.camera, this.renderTarget );

        // this.composer1.render();

        this.enterframeIgnoreCnt++;
    }

    resizeHandler(){
        this.sw = this.pack.top.sw;
        this.sh = this.pack.top.sh;
        this.scale = 1;
        let offScale = this.sh / 787;

        this.mesh.scale.set(this.scale,this.scale,this.scale);
        this.offMesh.scale.x = this.offMesh.scale.y = offScale;
    }
}


class Vertex{
    constructor(p, id, v){
        this.parent = p;
        this.id = id;
        this.v = v;
    }
    setPos(v){
        this.v.x = v.x;
        this.v.y = v.y;
        this.v.z = v.z;

        this.parent.g.attributes.position.array[this.id] = this.v.x;
        this.parent.g.attributes.position.array[this.id + 1] = this.v.y;
        this.parent.g.attributes.position.array[this.id + 2] = this.v.z;
    }
}

class MergeVertex{
    constructor(p, list){
        this.parent = p;
        this.list = list;
        this.conf = window.tokyo.pics.conf;
    }
    init(){
        let list = this.list;

        this._startX = list[0].v.x;
        this._startY = list[0].v.y;
        this._startZ = Math.random() * 0.16 - 0.08;//list[0].v.z;

        this._endX = list[0].v.x + Math.random() * 0.06 - 0.03;
        this._endY = list[0].v.y;
        this._endZ = Math.random() * 0.16 - 0.08;//list[0].v.z;

        this._currentX = this._startX;
        this._currentY = this._startY;
        this._currentZ = this._startZ;

        // this._startX = list[0].v.x + Math.random() * 0.2 - 0.1;
        // this._startY = list[0].v.y + Math.random() * 0.2 - 0.1;
        // this._startZ = list[0].v.z + Math.random() * 0.2 - 0.1;

        for( let i = 0, len = list.length; i < len; i++ ){
            let v = {};
            v.x = this._startX;
            v.y = this._startY;
            v.z = this._startZ;

            list[i].setPos(v);
        }
    }

    enterframe(){
        let conf = this.conf;


        this._currentX = (this._currentX * conf.s096a) + (this._endX * conf.s096b);
        this._currentY = (this._currentY * conf.s096a) + (this._endY * conf.s096b);
        this._currentZ = (this._currentZ * conf.s096a) + (this._endZ * conf.s096b);

        // this._currentX = (this._currentX * Math.random()) + (this._endX * Math.random());
        // this._currentY = (this._currentY * Math.random()) + (this._endY * Math.random());
        // this._currentZ = (this._currentZ * Math.random()) + (this._endZ * Math.random());

        let list = this.list;
        for( let i = 0; i < this.list.length; i++ )
        {
            let v = {};
            v.x = this._currentX;
            v.y = this._currentY;
            v.z = this._currentZ;
            this.list[i].setPos( v );
        }

        if( Math.abs( this._currentZ - this._endZ ) < 0.01 )
        {
            this._endX = this._startX + Math.random() * 0.2 - 0.1;
            this._endY = this._startY + Math.random() * 0.2 - 0.1;
            this._endZ = Math.random() * 0.4 - 0.2;//list[0].v.z;
        }
    }
}
