import {ua} from './libs/daijima/ua';
import Config from './Config';
import MainVisual from './MainVisual';
import {gsap, Expo, Quart} from "gsap";

window.tokyo = window.tokyo || {};
if(!window.tokyo.pics) window.tokyo.pics = {};
window.tokyo.pics.ua = ua;
window.tokyo.pics.BP = 900;
window.tokyo.pics.hasTouch = ("ontouchstart" in window);
window.tokyo.pics.PATH = "/manage/wp-content/themes/pics/top/";
window.tokyo.pics.commonTexture;

window.tokyo.pics.callback_scope;
window.tokyo.pics.callback;
window.tokyo.pics.hasOpeningMotion;
window.tokyo.pics.videoPath;
window.tokyo.pics.replaceImgPath;

class Top{
    constructor(){
        this.pack = window.tokyo.pics;
        this.enterframeList = [];
        this.enterframeIgnoreCnt = 0;
        this.resizeList = [];
        this.currentNav = 0;
        this.navScrollX = 0;
        this.navWidth;
        this.isFirefox = this.pack.ua.isFirefox;
        this.playingInitMotion = true;

        //Swipe
        this.swipeIntervalCnt = 0;
        this.swipeInterval;
        this.swipeStartPoint = {x:0, y:0};
        this.swipeStartAbsoluteX;
        this.swiped = false;
        this.pastSwipeX = 0;
        this.swipeX = 0;

        this.animationFrame;
    }

    init(){
        this.pack.conf = new Config();
        this.pack.mv = this.mv = new MainVisual();

        this.setDom();
        this.initEvents();
        this.startEnterframe();
    }

    skipLoading(){
        this.loading.classList.add("hide");
        this.nav.classList.add("show");
        this.scrollUI.classList.add("show");

        this.playingInitMotion = false;
        this.pack.callback.call(this.pack.callback_scope);
    }

    hideLoading(){
        let sc = this;
        let waitTime = 1.5;   //1.5秒ほど待ってからスタート

        gsap.to(this.loadingTxt, .4, {delay:waitTime + .6, opacity:0, ease:Quart.easeOut});
        gsap.to(this.loadingMark, .3, {delay:waitTime + 1.5, opacity:0, ease:Quart.easeInOut, onComplete:function(){
            sc.loading.classList.add("hide");
        }});
        gsap.to(this.loadingMask, 1.2, {delay:waitTime, x:this.sw, ease:Expo.easeInOut});
        gsap.delayedCall(1.8, this.start.bind(this));
    }

    start(){
        let h1Width = this.h1.clientWidth;

        let scrollUIWidth = 195;
        let sc = this;

        this.h1.classList.add("show");
        gsap.fromTo(this.h1, .9, {width:0}, {delay:.5, width:h1Width, ease:Quart.easeInOut, onComplete:function(){
            sc.h1.classList.add("completed");
        }});

        this.nav.classList.add("show");

        //PC NAV
        for( let i = 0, len = this.navListLiContainerPCImg.length; i < len; i++ ){
            let item = this.navListLiContainerPCImg[i];
            gsap.fromTo(item, .5, {y:112}, {delay:1.085 + i * .025, y:0, ease:Expo.easeOut});
        }

        //SP NAV
        for( let i = 0, len = this.navListLiContainerSPImg.length; i < len; i++ ){
            let item = this.navListLiContainerSPImg[i];
            gsap.fromTo(item, .5, {y:112}, {delay:1.085 + i * .015, y:0, ease:Expo.easeOut});
        }

        //SP LINE
        gsap.fromTo(this.sp_line_child, .5, {scaleX:0}, {delay:1.58, scaleX:1, ease:Expo.easeOut});

        //SCROLL UI
        this.scrollUI.classList.add("show");
        this.scrollUI.classList.add("addOverflow");
        gsap.fromTo(this.scrollUI, .8, {width:0}, {delay:1.3, width:scrollUIWidth, ease:Quart.easeInOut, onComplete:function(){
            sc.playingInitMotion = false;
            sc.pack.callback.call(sc.pack.callback_scope);
        }});
    }

    setDom(){
        this.loading = document.querySelector('.loading');
        this.loadingMask = document.querySelector('.loading .mask');
        this.loadingMark = document.querySelector('.loading .mark');
        this.loadingTxt = document.querySelector('.loading .txt');
        this.h1 = document.querySelector('h1');
        this.nav = document.querySelector('.top_nav');
        this.navListLi = document.querySelectorAll('.top_nav ul li');
        this.navListLiContainerPC = document.querySelectorAll('.top_nav ul li .pc.container');
        this.navListLiContainerSP = document.querySelectorAll('.top_nav ul li .sp.container');
        this.navListLiContainerPCImg = document.querySelectorAll('.top_nav ul li .pc.container img');
        this.navListLiContainerSPImg = document.querySelectorAll('.top_nav ul li .sp.container img');
        this.navListLiOver = document.querySelectorAll('.top_nav ul li .pc.over');
        this.navListA = document.querySelectorAll('.top_nav ul li a');
        this.navListImg = document.querySelectorAll('.top_nav ul li a img.pc');
        this.navUl = document.querySelector('.top_nav ul');
        this.scrollUI = document.querySelector('.scroll_ui');
        this.prev = document.querySelector('.scroll_ui span.prev');
        this.next = document.querySelector('.scroll_ui span.next');
        this.sp_line = document.querySelector('nav ul li:nth-child(5) div.sp.line');
        this.sp_line_child = document.querySelector('nav ul li:nth-child(5) div.sp.line span');
    }

    initEvents(){
        this.mousewheelEvent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
        this.swipeStartEvent = (this.pack.hasTouch) ? "touchstart" : "mousedown";
        this.swipingEvent = (this.pack.hasTouch) ? "touchmove" : "mousemove";
        this.swipeStopEvent = (this.pack.hasTouch) ? "touchend" : "mouseup";

        this.bindStartSwipeHandler = this.startSwipeHandler.bind(this);
        this.bindSwipingHandler = this.swipingHandler.bind(this);
        this.bindStopSwipeHandler = this.stopSwipeHandler.bind(this);
        this.bindClickNavHandler = this.clickNavHandler.bind(this);
        this.bindMouseWheelHandler = this.mouseWheelHandler.bind(this);
        this.bindMouseOverScrollUIHandler = this.mouseOverScrollUIHandler.bind(this);
        this.bindMouseOutScrollUIHandler = this.mouseOutScrollUIHandler.bind(this);

        this.resizeEvent = navigator.userAgent.match(/(iPhone|iPod|iPad)/) ? 'orientationchange' : 'resize';
        window.addEventListener(this.resizeEvent, this.resizeHandler.bind(this), false);

        document.addEventListener(this.mousewheelEvent, this.bindMouseWheelHandler, {passive: false});

        for( let i = 0, len = this.navListA.length; i < len; i++ ){
            let target = this.navListA[i];
            target.addEventListener("mouseout", {id:i, scope:this, handleEvent:this.mouseOutHandler});
            target.addEventListener("mouseover", {id:i, scope:this, handleEvent:this.mouseOverHandler});
        }

        this.prev.addEventListener("click", this.clickPrevHandler.bind(this));
        this.next.addEventListener("click", this.clickNextHandler.bind(this));

        this.navUl.addEventListener(this.swipeStartEvent, this.bindStartSwipeHandler);
        this.navUl.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);


        for( let i = 0, len = this.navListA.length; i < len; i++ ){
            let nav = this.navListA[i];
            nav.addEventListener("click", this.bindClickNavHandler.bind(this));
        }

        this.scrollUI.addEventListener("mouseover", this.bindMouseOverScrollUIHandler.bind(this));
        this.scrollUI.addEventListener("mouseout", this.bindMouseOutScrollUIHandler.bind(this));
        this.resizeHandler();
    }

    mouseOverScrollUIHandler(event){
        this.scrollUI.classList.add("over");
    }

    mouseOutScrollUIHandler(event){
        this.scrollUI.classList.remove("over");
    }

    slide(inputX){
        let sc = this, dr = 1.1;
        gsap.killTweensOf(this.navUl);
        gsap.to(this, dr, {navScrollX:inputX, onUpdate:function(){

                //スワイプ中にカレント変数を更新
                let width = 0;
                for( let i = 0, len = sc.navListLi.length; i < len; i++ ){
                    width -= sc.navListLi[i].clientWidth;
                    if(sc.navScrollX > width) {
                        sc.currentNav = i+1;
                        break;
                    }
                }

                sc.navUl.style.transform = 'translate3d(' + sc.navScrollX + 'px, 0, 0)';
            }, ease:Quart.easeOut});
    }

    mouseWheelHandler(event){
        if(this.sw <= this.pack.BP) return;
        this.getNavWidth();
        if (!event) event = window.event; //for legacy IE
        let delta = event.deltaY ? -(event.deltaY) : event.wheelDelta ? event.wheelDelta : -(event.detail);
        let minX = -this.navWidth + this.sw;

        if(this.isFirefox) delta *= 16;
        else delta *= 8;

        let inputX = this.navScrollX + delta;
        if(inputX <= minX) inputX = minX;
        else if(inputX >= 0) inputX = 0;

        this.pastSwipeX = inputX;
        this.slide(inputX);
    }

    clickNavHandler(event){
        //swipe処理とのバッティングを避ける処理
        if(this.sw <= this.pack.BP) return;
        if(this.swipeIntervalCnt > 3 && this.swiped) {
            event.preventDefault();
        }
    }

    startSwipeHandler(event){
        if(this.sw <= this.pack.BP) return;
        this.getNavWidth();
        let x, sc = this;
        let hasTouch = event.type.indexOf('touch') == 0;
        let moveRatio = this.navWidth / this.sw;
        this.swipeIntervalCnt = 0;
        this.swipeInterval = setInterval(function(){
            sc.swipeIntervalCnt++;
        }, 1000/30);

        if(hasTouch) {
            let touches = event.changedTouches[0];
            this.swipeStartAbsoluteX = touches.clientX;
            x = touches.clientX * moveRatio;
        }else{
            event.preventDefault();
            this.swipeStartAbsoluteX = event.clientX;
            x = event.clientX * moveRatio;
        }

        this.swipeStartPoint.x = x - this.pastSwipeX;

        document.addEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.addEventListener(this.swipingEvent, this.bindSwipingHandler);
    }

    swipingHandler(event){
        if(this.sw <= this.pack.BP) return;
        let x, sc = this;
        let minX = -this.navWidth + this.sw;
        let hasTouch = event.type.indexOf('touch') == 0;
        let moveRatio = this.navWidth / this.sw;

        if(hasTouch) {
            // event.preventDefault();
            let touches = event.changedTouches[0];
            x = touches.clientX * moveRatio;
        }else{
            event.preventDefault();
            x = event.clientX * moveRatio;
        }

        let inputX = (x - this.swipeStartPoint.x);
        if(inputX <= minX) inputX = minX;
        else if(inputX >= 0) inputX = 0;

        this.swipeX = inputX;

        this.slide(inputX);

        //cursor
        this.navUl.classList.add("grab");
    }

    stopSwipeHandler(event){
        if(this.sw <= this.pack.BP) return;
        let x, sc = this;
        let hasTouch = event.type.indexOf('touch') == 0;

        if(hasTouch) {
            let touches = event.changedTouches[0];
            x = touches.clientX;
        }else{
            x = event.clientX;
        }

        if(this.swipeStartAbsoluteX === x) this.swiped = false;
        else this.swiped = true;

        // this.pastSwipeX = this.navScrollX;
        this.pastSwipeX = this.swipeX;
        clearInterval(this.swipeInterval);
        document.removeEventListener(this.swipeStopEvent, this.bindStopSwipeHandler);
        document.removeEventListener(this.swipingEvent, this.bindSwipingHandler);

        //cursor
        this.navUl.classList.remove("grab");
    }

    clickPrevHandler(event){
        if(this.currentNav === 0) return;
        this.getNavWidth();
        this.currentNav--;
        let sc = this;
        let dr = .5;
        let minX = -this.navWidth + this.sw;
        let width = 0;
        for( let i = 0, len = this.currentNav; i < len; i++ ){
            width -= this.navListLi[i].clientWidth;
        }
        if(width <= minX) width = minX;

        gsap.killTweensOf(this.navUl);
        gsap.to(this, dr, {navScrollX:width, onUpdate:function(){
            sc.navUl.style.transform = 'translate3d(' + sc.navScrollX + 'px, 0, 0)';
        }, ease:Quad.easeInOut});
        this.pastSwipeX = width;
    }

    clickNextHandler(event){
        if(this.currentNav === this.navListLi.length - 1) return;
        this.getNavWidth();
        this.currentNav++;
        let sc = this;
        let dr = .5;
        let minX = -this.navWidth + this.sw;
        let width = 0;
        for( let i = 0, len = this.currentNav; i < len; i++ ) {
            width -= this.navListLi[i].clientWidth;
        }

        if(width <= minX) {
            //これ以上は左に移動しない時は一度加算した値を戻す
            width = minX;
            if(this.pastSwipeX === width) {
                this.currentNav--;
            }
        }


        gsap.killTweensOf(this.navUl);
        gsap.to(this, dr, {navScrollX:width, onUpdate:function(){
            sc.navUl.style.transform = 'translate3d(' + sc.navScrollX + 'px, 0, 0)';
        }, ease:Quad.easeInOut});
        this.pastSwipeX = width;
    }

    addEnterframe(target, func){
        let obj = {target:target, func:func};
        this.enterframeList.push(obj);
    }

    removeEnterframe(target){

        let id = -1;
        for( let i = 0, len = this.enterframeList.length; i < len; i++ ){
            if(this.enterframeList[i].target === target){
                id = i;
                break;
            }
        }
        if(id === -1) return;

        this.enterframeList.splice(id, 1);
    }

    addResize(target, func){
        let obj = {target:target, func:func};
        this.resizeList.push(obj);
    }

    removeResize(target){
        let id = -1;
        for( let i = 0, len = this.resizeList.length; i < len; i++ ){
            if(this.resizeList[i].target === target){
                id = i;
                break;
            }
        }
        if(id === -1) return;

        this.resizeList.splice(id, 1);
    }

    mouseOutHandler(event){
        if(this.scope.playingInitMotion) return;
        let id = this.id;
        this.scope.navListLiOver[id].style.opacity = 0;
        this.scope.navListLiContainerPC[id].style.opacity = 1;
        // this.scope.navListImg[id].src = window.tokyo.pics.PATH + "img/menu" + (id + 1) + ".png";
        this.scope.pack.mv.out(id);
    }

    mouseOverHandler(event){
        if(this.scope.playingInitMotion) return;
        let id = this.id;
        this.scope.navListLiOver[id].style.opacity = 1;
        this.scope.navListLiContainerPC[id].style.opacity = 0;
        // this.scope.navListImg[id].src = window.tokyo.pics.PATH + "img/menu" + (id + 1) + "b.png";
        this.scope.pack.mv.over(id);
    }

    startEnterframe(){
        this.enterframe();
    }

    enterframe(){
        this.animationFrame = window.requestAnimationFrame(this.enterframe.bind(this));

        // if(this.enterframeIgnoreCnt % 2 === 0){
            for( let i = 0, len = this.enterframeList.length; i < len; i++ ){
                let obj = this.enterframeList[i];

                if(obj !== undefined){
                    let target = obj.target;
                    let func = obj.func;
                    func.call(target);
                }
            }
        // }


        // this.enterframeIgnoreCnt++;
    }

    getNavWidth(){
        let left = this.nav.getBoundingClientRect().left;
        this.navWidth = this.navUl.clientWidth + (left * 2);
    }

    resizeHandler(event){
        let sc = this;

        if(event !== undefined && event.type === "orientationchange"){
            setTimeout(function(){
                sc.executeResize();
            }, 100);
        }else{
            this.executeResize();
        }
    }

    executeResize(){
        this.sw = window.innerWidth;
        this.sh = window.innerHeight;
        this.swh = this.sw/2;
        this.shh = this.sh/2;

        for( let i = 0, len = this.resizeList.length; i < len; i++ ){
            let obj = this.resizeList[i];
            let target = obj.target;
            let func = obj.func;
            func.call(target);
        }
        this.getNavWidth();
    }
}

(function(){
    window.tokyo.pics.top = new Top();
})();

window.tokyo.pics.start = function(scope, callback, hasOpeningMotion, videoPath, replaceImgPath){
    let pack = window.tokyo.pics;
    pack.callback_scope = scope;
    pack.callback = callback;
    pack.hasOpeningMotion = hasOpeningMotion;
    pack.videoPath = videoPath;
    pack.replaceImgPath = replaceImgPath;
    pack.top.init();
}