import {gsap, Quart, Quad} from "gsap";

export default class{
    constructor(parent){

        let sc = this;
        this.pack = window.tokyo.pics;
        this.texPath = 'img/bg_mark.png';
        this.parent = parent;
        this.texture;
        this.width = 1400;
        this.height = 787;
        this.loadTexture(this.pack.PATH + this.texPath).then(function(){
           sc.loadHandler();
        });
    }
    loadTexture(path){
        let sc = this;
        let loader = new THREE.TextureLoader();

        return new Promise((resolve) =>{
            loader.load(
                path,
                function ( texture ) {
                    // var material = new THREE.MeshBasicMaterial( {
                    //     map: texture
                    // } );

                    texture.needsUpdate = true;
                    texture.minFilter=THREE.LinearFilter;
                    texture.magFilter=THREE.LinearFilter;


                    sc.texture = texture;
                    return resolve();
                },
                undefined,
                function ( err ) {
                    console.error( 'An error happened.' );
                }
            );
            loader.load(path);
        });
    }

    loadHandler(){
        this.initMesh();
    }

    initMesh(){
        this.g = new THREE.PlaneGeometry(348, 787);

        this.m = new THREE.MeshBasicMaterial({
            transparent:true,
            map:this.texture
        });

        this.mesh = new THREE.Mesh(this.g, this.m);
        this.parent.scene.add(this.mesh);
        this.pack.top.addResize(this, this.resizeHandler);
        this.resizeHandler();
    }

    out(){
        if(this.m) {
            gsap.killTweensOf(this.m);
            gsap.to(this.m, 1.0, {opacity:1, ease:Quad.easeInOut});
            // this.m.opacity = 1;
        }
    }

    over(){
        if(this.m) {
            gsap.killTweensOf(this.m);
            this.m.opacity = 0;
        }

/*        this.m.opacity = (this.m.opacity * _main._conf.s095a);
        if( this.m.opacity < 0.01 )
        {
            this.m.opacity = 0;
        }*/
    }
    contentsStart(){

    }

    resizeHandler(){
        let sh = 1 / this.pack.mv.bg.mesh.scale.y;
        let sh2 = window.innerHeight / 787;
        let scale = sh * sh2;
        this.mesh.scale.x = this.mesh.scale.y = scale;
    }
}