export default class{
    constructor(){
        this.pack = window.tokyo.pics;

        this.init();
    }

    init(){
        this.initScene();
    }

    initShader(){
        this.vertexShaderSrc = `
            varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 mvPos;
            
            void main()
            {
                vNormal = normalMatrix * normal;
                
                vUv = uv;
                
                vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );	
                gl_Position = projectionMatrix * mvPosition;
                
                //視野座標系の頂点座標
                mvPos = mvPosition.xyz;
            }
        `;

        this.fragmentShaderSrc = `
            uniform sampler2D texture;
            
            uniform float alpha;
            uniform float isLight;
            uniform vec3 baseColor;
            
            varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 mvPos;
            
            uniform vec2 resolution;
            uniform float time;
            
		
	    	float random(vec2 st) {
              return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
            }
      
            void main()
            {   
                /*
                LIGHT( PHONG )
                */
                vec3 lightColor = vec3(1.0, 1.0, 1.0);
                // vec3 lightPos = vec3(1.5, 1.5, 1.0);
                vec3 lightPos = vec3(1.5, 1.5, 3.5);
                
                
                if( isLight > 0.0 )
                {
                    // 拡散光の計算 ---------------------------------------
                    // 視点座標系の光線ベクトル
                    vec4 viewLightPosVec4 = viewMatrix * vec4( lightPos, 0.0 );
            
                    // 光源ベクトルを正規化
                    vec3 _l = normalize( viewLightPosVec4.xyz );
            
                    // 法線ベクトルを正規化
                    vec3 _n = normalize( vNormal );
            
                    // 光源と法線の内積(ratio)を求める
                    float _ratio = dot( _n, _l );
            
                    //裏面対策 ベクトル反転
                    if( !gl_FrontFacing ) _ratio = - _ratio;
            
                    //拡散色
                    vec3 _diffuse = ( baseColor * lightColor * max( _ratio, 0.0 ) );// - vec3( 0.6, 0.6, 0.6 );
                    // vec3 _diffuse = ( baseColor * lightColor * max( _ratio, 0.0 ) ) - vec3( -0.1, -0.1, -0.1 );
            
            
                    //鏡面効果（演算軽減版）
                    vec3 _c = - normalize( mvPos );
                    vec3 _h = normalize( _l + _c );
                    float _ratio2 = dot( _n, _h );
                    if( !gl_FrontFacing ) _ratio2 = - _ratio2;//裏面対応
                    vec3 _specular = lightColor * pow( max( _ratio2, 0.0 ), 50.0 );
            
                    // 描画色 = テクスチャーカラー(拡散色)✕拡散強度 + 反射光
                    // gl_FragColor = vec4( _diffuse + _specular, alpha);
                    // gl_FragColor = vec4(rand(1.0 * time * vUV.x), rand(2.0 * time * vUV.y), rand(3.0 * time), 1.0);
                    // gl_FragColor = vec4(rand(1.0 * time * gl_FragCoord.x), rand(2.0 * time * gl_FragCoord.y), rand(3.0 * time), 1.0);

                    //PolygonのPhongの状態の値
                    // vec4 result = vec4( _diffuse + _specular, alpha);
                    vec3 result = vec3( _diffuse + _specular);
                    
                    vec2 st2 = gl_FragCoord.xy/resolution.xy;
                    float rnd = random(st2*time);
                    vec3 noise_result = vec3(rnd) / 7.;
                    
                    // gl_FragColor = vec4( baseColor, alpha);
                    // gl_FragColor = vec4(result + noise_result, alpha);                    
                    gl_FragColor = vec4(baseColor + noise_result, alpha);                    
                }
                else
                {
                    gl_FragColor = vec4( baseColor, alpha);
                }
            }
        `;
    }

    initScene(){

        this.clock = new THREE.Clock();

        this.width = 1400;
        this.height = 787;

        this.initShader();

        this.alpha = 0.75;
        this.isLight = 1;
        // this.baseColor = new THREE.Vector3(.77, .77, .77);
        this.baseColor = new THREE.Vector3(0, 0, 0);

        this.scene = new THREE.Scene();

        this.setViewPort();
        this.camera = new THREE.OrthographicCamera(
            this.viewPort.left,
            this.viewPort.right,
            this.viewPort.top,
            this.viewPort.bottom,
            this.viewPort.near,
            this.viewPort.far
        );

        this.camera.position.set( 0, 0, 100 );

        this.renderTarget = new THREE.WebGLRenderTarget( this.width, this.height,
            {
                magFilter: THREE.LinearFilter,
                minFilter: THREE.LinearFilter,
                wrapS: THREE.ClampToEdgeWrapping,
                wrapT: THREE.ClampToEdgeWrapping,
            });

        this.count = 0;

        let g = new THREE.PlaneGeometry( this.width, this.height );

        this.shader = {
            vertexShader: this.vertexShaderSrc,
            fragmentShader: this.fragmentShaderSrc,
            uniforms:
                {
                    time:{value:0.0},
                    resolution: {value: new THREE.Vector2(window.innerWidth, window.innerHeight)},
                    alpha:{value:this.alpha},
                    isLight:{value:this.isLight},
                    baseColor:{value:this.baseColor},
                },
            depthTest:false,
            // depthTest:true,
            depthWrite:false,
            transparent:true,
            wireframe:false,
        };

        this.material = new THREE.ShaderMaterial( this.shader );

        this.mesh = new THREE.Mesh( g, this.material );
        this.scene.add( this.mesh );

        this.contentsStart();

    }

    contentsStart(){
        this.pack.top.addEnterframe(this, this.enterframe);
    }

    setViewPort() {
        this.viewPort = {};

        var w = this.width;
        var h = this.height;
        var viewSize = h;
        var aspectRatio = w / h;

        this.viewPort = {
            viewSize: viewSize,
            aspectRatio: aspectRatio,
            left: (-aspectRatio * viewSize) / 2,
            right: (aspectRatio * viewSize) / 2,
            top: viewSize / 2,
            bottom: -viewSize / 2,
            near: 0,
            far: 10000
        }
    }

    contentsStart(){
        this.pack.top.addEnterframe(this, this.enterframe);
    }

    enterframe(){
        this.pack.mv.renderer.render( this.scene, this.camera, this.renderTarget );
        // this.material.needsUpdate = true;
        const time = this.clock.getElapsedTime();
        this.material.uniforms.time.value = time;
    }
}