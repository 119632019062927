import EventDispatcher from './libs/daijima/EventDispatcher';
import {gsap, Quart, Cubic} from "gsap";

export default class extends EventDispatcher {
    constructor(parent) {
        super();
        this.pack = window.tokyo.pics;
        this.parent = parent;
        this.model;
        this.enterframeIgnoreCnt = 0;
        this.swingWidth = 80;
        this.currentSwingList = [];
        this.currentSwingWidth = this.swingWidth;

        this.basePositions = [
            {x:-137.6, y:-449.65},
            {x:-142.75, y:-445.45},
            {x:-146.9, y:-439.25},
            {x:-145.3, y:-431.7},
            {x:-142.75, y:-422.3},
            {x:-126.75, y:-370.1},
            {x:-116.55, y:-340.95},
            {x:-107.75, y:-313.55},
            {x:-94.55, y:-270.75},
            {x:-80.75, y:-226.75},
            {x:-65.15, y:-176.55},
            {x:-46.55, y:-117.75},
            {x:-30.95, y:-71.15},
            {x:-22.6, y:-45.6},
            {x:-5.55, y:1.15},
            {x:-16.35, y:36.1},
            {x:-34.15, y:89.5},
            {x:-45.35, y:125.9},
            {x:-66.15, y:191.5},
            {x:-77.75, y:231.1},
            {x:-90.15, y:267.9},
            {x:-100.15, y:301.5},
            {x:-113.75, y:343.1},
            {x:-124.55, y:379.9},
            {x:-143.35, y:433.9},
            {x:-143.35, y:442.7},
            {x:-138.15, y:447.1},
            {x:-127.75, y:450.3},
            {x:-91.65, y:450.3},
            {x:-52.25, y:450.3},
            {x:-11.75, y:450.3},
            {x:1.9, y:450.3},
            {x:6, y:448.1},
            {x:10.2, y:444.15},
            {x:13.25, y:434.25},
            {x:23.4, y:402.35},
            {x:30.9, y:374.6},
            {x:40.55, y:345.05},
            {x:52.2, y:309.85},
            {x:61.7, y:279.65},
            {x:73.55, y:244.4},
            {x:83.65, y:209.45},
            {x:100.6, y:154.2},
            {x:112.05, y:119.4},
            {x:122.6, y:85.9},
            {x:133, y:55.5},
            {x:147.7, y:7.1},
            {x:138.9, y:-24.5},
            {x:127.4, y:-59},
            {x:111.6, y:-109.8},
            {x:92.6, y:-171.85},
            {x:76.8, y:-223.5},
            {x:62.1, y:-269.3},
            {x:48.75, y:-309.7},
            {x:37.75, y:-346.35},
            {x:26.2, y:-380.6},
            {x:10.15, y:-432.55},
            {x:6.65, y:-443.95},
            {x:-1.3, y:-450.3},
            {x:-21.4, y:-450.25},
            {x:-51.25, y:-450.25},
            {x:-93.3, y:-450.25},
            // {x:-137.6, y:-449.65},
        ];

        //連番のid付与
        for( let i = 0, len = this.basePositions.length; i < len; i++ ){
            let item = this.basePositions[i];
            item.id = i;
        }

        //中心から近いY軸順でソート
        this.basePositions.sort(function(a,b){
            let dis_a = Math.abs(a.y);
            let dis_b = Math.abs(b.y);
            if(dis_a < dis_b) return -1;
            if(dis_a > dis_b) return 1;
            return 0;
        });

        //ソート結果順のid付与
        for( let i = 0, len = this.basePositions.length; i < len; i++ ){
            let item = this.basePositions[i];
            item.sort_id = i;
        }

        //元の順番に戻す
        this.basePositions.sort(function(a,b){
            if(a.id < b.id) return -1;
            if(a.id > b.id) return 1;
            return 0;
        });

        this.tempVertices = [];
    }

    initMesh(){
        let points = [];

        // trace(this.basePositions.length);

        for( let h = 0; h < 17; h++ ){
            for( let i = 0, len = this.basePositions.length; i < len; i++ ){
                let p = this.basePositions[i];
                points.push( new THREE.Vector3(p.x+Math.random()*10-5, -p.y+Math.random()*10-5, 0));
            }
        }

        let material = this.material = new THREE.LineBasicMaterial({
            color: 0xffffff,
            transparent:true,
            opacity:.3
        });

        let curve = new THREE.CatmullRomCurve3(points);
        let curvePoints = curve.getPoints(points.length * 1);


        // trace(curvePoints);

        let geometry = this.g = new THREE.BufferGeometry().setFromPoints( curvePoints );
        // let geometry = this.g = new THREE.BufferGeometry().setFromPoints( points );
        this.mesh = new THREE.Line( geometry, material );
        this.mesh.position.z = 3;
        this.pack.top.addResize(this, this.resizeHandler);
        this.resizeHandler();

        //頂点数curveで倍にしているのでここで1/2にする
        this.verticesLength = this.g.attributes.position.count / 1;
    }

    //BufferGeometryの配列を動的に変更
    generateGeometryPosition(){
        let length = this.verticesCnt;
        // let length = this.g.attributes.position.count;
        let positions = new Float32Array( length * 3 ); // 3 vertices per point
        this.g.addAttribute( 'position', new THREE.BufferAttribute( positions, 3 ) );
        if(this.verticesCnt < this.verticesLength) this.verticesCnt += this.speed;
        if(this.verticesCnt > this.verticesLength) this.verticesCnt = this.verticesLength;
        this.speed++;
        if(this.speed >= 30) this.speed = 30;
    }

    start(){
        this.parent.scene.add(this.mesh);
        let scale = this.scale * 1.2;

        gsap.killTweensOf(this.mesh.scale);
        gsap.killTweensOf(this.material);
        gsap.killTweensOf(this);

        this.material.opacity = .3;
        gsap.to(this.mesh.scale, 1.5, {x:scale, y:scale, z:scale, ease:Quart.easeOut});

        this.verticesCnt = Math.floor(this.verticesLength*.7);     //初期のverticesの数
        this.speed = 10;
        this.currentSwingWidth = this.swingWidth;

        for( let i = 0, len = this.basePositions.length; i < len; i++ ){
            this.currentSwingList[i] = {value:16};
            gsap.to(this.currentSwingList[i], 0.7, {delay:i * .007 + Math.random() * .2, keyframes:[{value:this.swingWidth}, {value:16}], ease:Cubic.easeOut});
        }

        this.pack.top.removeEnterframe(this);
        this.pack.top.addEnterframe(this, this.enterframe);
    }

    stop(){
        let sc = this;
        gsap.killTweensOf(this.material);
        gsap.killTweensOf(this.mesh.scale);
        gsap.killTweensOf(this);

        gsap.to(this.material, .3, {delay:.5, opacity:0, ease:Quart.easeOut});
        gsap.to(this.mesh.scale, .8, {x:this.scale, y:this.scale, z:this.scale, ease:Quart.easeOut, onComplete:function(){
            sc.parent.scene.remove(sc.mesh);
            sc.pack.mv.resetPreviousPolygonID();
            sc.pack.top.removeEnterframe(sc);
        }});

        let len = this.basePositions.length;
        let timeLag = .004;
        let totalDelay = len * timeLag;

        for( let i = 0; i < len; i++ ){
            this.currentSwingList[i] = {value:16};
            gsap.to(this.currentSwingList[i], 0.3, {delay:totalDelay - (i * timeLag), keyframes:[{value:this.swingWidth}, {value:16}], ease:Cubic.easeOut});
        }
    }

    setTempVertices(i, p){
        let v = {};
        let id = i % this.basePositions.length;
        let sort_id = this.basePositions[id].sort_id;

        let currentSwingWidth = this.currentSwingList[sort_id].value;
        // let currentSwingWidthH = this.currentSwingWidth / 2;
        let currentSwingWidthH = currentSwingWidth / 2;
        v.x = p.x + Math.random() * currentSwingWidth - currentSwingWidthH + Math.random() * currentSwingWidth - currentSwingWidthH;
        v.y = p.y + Math.random() * currentSwingWidth - currentSwingWidthH + Math.random() * currentSwingWidth - currentSwingWidthH;
        v.z = p.z + Math.random() * currentSwingWidth - currentSwingWidthH + Math.random() * currentSwingWidth - currentSwingWidthH;

        this.tempVertices[i] = v;
        return v;
    }

    enterframe(){
        this.enterframeIgnoreCnt++;

        //BufferGeometryの配列を動的に変更
        // if(this.enterframeIgnoreCnt % 2 === 0) {
            this.generateGeometryPosition();
        // }


        let count = this.mesh.geometry.attributes.position.count;
        let count2 = this.basePositions.length;

        for( let i = 0, len = count; i < len; i++ ){
            let id = i % count2;
            let p = this.basePositions[id];
            //スピードを1/2にする
            if(this.enterframeIgnoreCnt % 4 === 0) {
                let v = this.setTempVertices(i, p);
                this.mesh.geometry.attributes.position.setX(i, v.x);
                this.mesh.geometry.attributes.position.setY(i, v.y);
            }else{
                let v = this.tempVertices[i];
                if(v){
                    this.mesh.geometry.attributes.position.setX(i, v.x);
                    this.mesh.geometry.attributes.position.setY(i, v.y);
                }else{
                    let v = this.setTempVertices(i, p);
                    this.mesh.geometry.attributes.position.setX(i, v.x);
                    this.mesh.geometry.attributes.position.setY(i, v.y);
                }
            }
        }
        this.mesh.geometry.attributes.position.needsUpdate = true;
    }

    enterframeThinout(){
        // trace("enterframeThinout");
    }

    resizeHandler(){
        let sh = window.innerHeight / 787;
        this.scale = sh * .83;
        this.mesh.scale.set(this.scale,this.scale,this.scale);
        // this.mesh.position.x = -75 * scale;
    }
}