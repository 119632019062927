import EventDispatcher from './libs/daijima/EventDispatcher';
import {gsap, Quart} from "gsap";

export default class extends EventDispatcher {
    constructor(parent) {
        super();
        let sc = this;
        this.pack = window.tokyo.pics;
        this.parent = parent;
        this.width = 1400;
        this.height = 787;
    }


    initMesh(){
        this.material = new THREE.MeshBasicMaterial({
            map: this.pack.mv.sandstorm.renderTarget.texture,
            // color:0x333333,
            wireframe: false,
            transparent:true,
            opacity: 0,
            blending: THREE.AdditiveBlending,
        });

        this.g = new THREE.PlaneGeometry(this.width, this.height, 2, 2);
        this.mesh = new THREE.Mesh(this.g, this.material);
        this.mesh.position.z = 200;
        this.pack.top.addResize(this, this.resizeHandler);
        this.resizeHandler();
    }

    start(){
        gsap.killTweensOf(this.material);
        gsap.to(this.material, 1.0, {opacity:.75, ease:Quart.easeOut});

        this.parent.scene.add(this.mesh);
    }

    stop(){
        let sc = this;
        gsap.killTweensOf(this.material);
        gsap.to(this.material, 1.0, {opacity:0, ease:Quart.easeOut, onComplete:function(){
            sc.parent.scene.remove(sc.mesh);
        }});
    }

    resizeHandler(){
        this.sw = this.pack.top.sw;
        this.sh = this.pack.top.sh;

        let scale = this.sh / 787;
        this.mesh.scale.x = this.mesh.scale.y = scale;
    }
}