import {gsap, Quart} from "gsap";

export default class{
    constructor() {
        this.pack = window.tokyo.pics;
        // this.pack.ua.isIE11 = true;  //for IE11 debug

        this.noVideo = false;
        if(this.pack.ua.isIE11) {
            this.noVideo = true;
            this.initImage();
        }else this.initVideo();
    }

    initVideo(){
        let sc = this;
        this.videoList = [];
        this.textureList = [];

        // let src = ["video/movie1.mp4"];

        // for( let i = 0, len = src.length; i < len; i++ ){
            let v = document.createElement('video');
            v.width = 960;
            v.height = 540;
            v.muted = true;
            v.preload = "auto";
            v.loop = true;
            v.autoplay = false;
            v.playsInline = true;
            // v.src = this.pack.PATH + src[i];
            v.onloadstart = function(){
                // trace("video onloadstart");
                gsap.delayedCall(.5, function(){
                    sc.pack.mv.loadDataHandler();
                });
            };
            v.oncanplay = function(){
                // trace("video oncanplay");
            }
            v.src = this.pack.videoPath;
            this.videoList.push(v);

            let vt = new THREE.VideoTexture(v);
            vt.minFilter = THREE.LinearFilter;
            vt.mapFilter = THREE.LinearFilter;
            vt.format = THREE.RGBFormat;
            this.textureList.push(vt);
        // }
    }

    initImage(){
        let sc = this;
        this.textureList = [];
        // let t = new THREE.TextureLoader().load( this.pack.PATH + 'video/movie1_for_ie.jpg' );
        let t = new THREE.TextureLoader();
        t.load(
            this.pack.replaceImgPath,
            function ( texture ) {
                sc.textureList.push(texture);
                sc.pack.mv.loadDataHandler();
            },
            undefined,
            function ( err ) {
                console.error( 'An error happened. at VideoManager' );
            }
        );

        t.minFilter = THREE.LinearFilter;
        t.mapFilter = THREE.LinearFilter;
        t.format = THREE.RGBFormat;
    }

    play(id){
        if(this.noVideo) return false;
        this.videoList[id-1].play();
    }

    getTexture(id){
        return this.textureList[id-1];
    }

    stop(id){
        if(this.noVideo) return false;
        this.videoList[id-1].pause();
    }

    reset(id){
        if(this.noVideo) return false;
        this.videoList[id-1].pause();
        this.videoList[id-1].currentTime = 0;
    }

    getCurrentTime(id){
        if(this.noVideo) return false;
        return this.videoList[id-1].currentTime;
    }
}