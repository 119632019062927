import EventDispatcher from './libs/daijima/EventDispatcher';
import {gsap, Quart, Quad} from "gsap";

export default class extends EventDispatcher {
    constructor(parent){
        super();

        let sc = this;
        this.pack = window.tokyo.pics;
        this.parent = parent;

        this.objPath = "models/01_poly_random_uv.obj";

        this.polygonOpacity = .7;
        this.defVertexZ = -1;
        this.enterframeIgnoreCnt = 0;

        this.model;
        this.texture;
        this.mtlMaterial;


        Promise.all([this.loadOBJ(this.pack.PATH + this.objPath)]).then(function(){
            sc.loadHandler();
        });
    }

    loadTexture(){
        let sc = this;
        let loader = new THREE.TextureLoader();


        return new Promise((resolve) => {

            loader.load(
            this.pack.PATH + this.imgPath,
            function ( texture ) {
                sc.texture = sc.pack.commonTexture = texture;
                sc.texture.wrapS = THREE.RepeatWrapping;
                sc.texture.wrapT = THREE.RepeatWrapping;

                return resolve();
            },

            // onProgress callback currently not supported
            undefined,

            // onError callback
            function ( err ) {
                console.error( 'An error happened. at Polygon' );
            }

            );
        });


    }

    loadMTL(path){
        let sc = this;
        let loader = new THREE.MTLLoader();

        return new Promise((resolve) =>{
            loader.load(path,
                function (materials) {

                    sc.mtlMaterial = materials;
                    sc.mtlMaterial.preload();
                    return resolve();
                }
            );
        });
    }

    loadOBJ(path) {
        let sc = this;
        let loader = new THREE.OBJLoader();
        return new Promise((resolve) => {
            loader.load(
                path,
                function ( object ) {
                    sc.model = object;
                    return resolve();
                },
                function ( xhr ) {
                    // trace( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                },
                function ( error ) {
                    console.error( 'An error happened at Polygon' );
                }
            );
        });
    }

    loadHandler(){
        this.dispatchEvent("load");
    }

    initShader(){
        this.vertexShaderSrc = `
            // varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 mvPos;
            
            void main()
            {
                vNormal = normalMatrix * normal;
                
                // vUv = uv;
                
                vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );	
                gl_Position = projectionMatrix * mvPosition;
                
                //視野座標系の頂点座標
                mvPos = mvPosition.xyz;
            }
        `;

        this.framgmentShaderSrc = `
            uniform sampler2D texture;
            
            uniform float alpha;
            uniform float isLight;
            uniform vec3 baseColor;
            
            // varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 mvPos;
            
            uniform vec2 resolution;
            uniform float time;
            
		
	    	float random(vec2 st) {
              return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
            }
      
            void main()
            {   
                /*
                LIGHT( PHONG )
                */
                vec3 lightColor = vec3(1.0, 1.0, 1.0);
                // vec3 lightPos = vec3(1.5, 1.5, 1.0);
                vec3 lightPos = vec3(1.5, 1.5, 3.5);
                
                
                if( isLight > 0.0 )
                {
                    // 拡散光の計算 ---------------------------------------
                    // 視点座標系の光線ベクトル
                    vec4 viewLightPosVec4 = viewMatrix * vec4( lightPos, 0.0 );
            
                    // 光源ベクトルを正規化
                    vec3 _l = normalize( viewLightPosVec4.xyz );
            
                    // 法線ベクトルを正規化
                    vec3 _n = normalize( vNormal );
            
                    // 光源と法線の内積(ratio)を求める
                    float _ratio = dot( _n, _l );
            
                    //裏面対策 ベクトル反転
                    if( !gl_FrontFacing ) _ratio = - _ratio;
            
                    //拡散色
                    vec3 _diffuse = ( baseColor * lightColor * max( _ratio, 0.0 ) );// - vec3( 0.6, 0.6, 0.6 );
                    // vec3 _diffuse = ( baseColor * lightColor * max( _ratio, 0.0 ) ) - vec3( -0.1, -0.1, -0.1 );
            
            
                    //鏡面効果（演算軽減版）
                    vec3 _c = - normalize( mvPos );
                    vec3 _h = normalize( _l + _c );
                    float _ratio2 = dot( _n, _h );
                    if( !gl_FrontFacing ) _ratio2 = - _ratio2;//裏面対応
                    vec3 _specular = lightColor * pow( max( _ratio2, 0.0 ), 50.0 );
            
                    // 描画色 = テクスチャーカラー(拡散色)✕拡散強度 + 反射光
                    // gl_FragColor = vec4( _diffuse + _specular, alpha);
                    // gl_FragColor = vec4(rand(1.0 * time * vUV.x), rand(2.0 * time * vUV.y), rand(3.0 * time), 1.0);
                    // gl_FragColor = vec4(rand(1.0 * time * gl_FragCoord.x), rand(2.0 * time * gl_FragCoord.y), rand(3.0 * time), 1.0);

                    //PolygonのPhongの状態の値
                    // vec4 result = vec4( _diffuse + _specular, alpha);
                    vec3 result = vec3( _diffuse + _specular);
                    
                    vec2 st2 = gl_FragCoord.xy/resolution.xy;
                    float rnd = random(st2*time);
                    vec3 noise_result = vec3(rnd) / 7.;
                    
                    gl_FragColor = vec4(result + noise_result, alpha);                    
                }
                else
                {
                    gl_FragColor = vec4( baseColor, alpha);
                }
            }

        `;
    }

    initMesh(){

        this.material = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            // map:this.texture,
            emissive: 0x111111,
            // roughness:1,
            // metalness:0.5,
            // flatShading: true,
            transparent: true,
            opacity:this.polygonOpacity,
        });
        // this.material.needsUpdate = true;
        // this.material.map.needsUpdate = true;


/*
        this.clock = new THREE.Clock();
        this.initShader();
        this.alpha = .8;
        this.isLight = 1;
        this.baseColor = new THREE.Vector3(1, 1, 1);

        this.shader = {
            vertexShader: this.vertexShaderSrc,
            fragmentShader: this.framgmentShaderSrc,
            uniforms:
                {
                    time:{value:0.0},
                    resolution: {value: new THREE.Vector2(window.innerWidth, window.innerHeight)},
                    alpha:{value:this.alpha},
                    isLight:{value:this.isLight},
                    baseColor:{value:this.baseColor},
                },
            depthTest:false,
            depthWrite:false,
            transparent:true,
            wireframe:false,
        };
        this.material = new THREE.ShaderMaterial( this.shader );*/


        let g = this.g = this.model.children[0].geometry;
        let g_array = g.attributes.position.array;
        let g_array_length = g_array.length;
        let vertices = this.defVertices = [];

        for( let i = 0; i < g_array_length; i+=3 ){
            let v = {};
            v.x = g_array[i];
            v.y = g_array[i+1];
            v.z = g_array[i+2];
            let v_obj = new Vertex(this, i, v);
            vertices.push(v_obj);
        }

        this.mergeVertices = [];
        let mergeVertex = new MergeVertex(this, [vertices[0]]);
        this.mergeVertices.push(mergeVertex);
        for( let i = 1, len = vertices.length; i < len; i++ ){
            this.checkMerge(vertices[i]);
        }

        for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
            let mergeVertex = this.mergeVertices[i];
            mergeVertex.init();
        }

        //頂点のY軸でソートする
        this.mergeVertices.sort(function(a,b){
           // if(a._startY > b._startY) return -1;
           if(a.distanceFromZero < b.distanceFromZero) return -1;
           if(a.distanceFromZero > b.distanceFromZero) return 1;
           return 0;
        });

        this.mesh = new THREE.Mesh(this.g, this.material);
        this.mesh.scale.set(125,125,125);
        this.mesh.position.z = 100;

        //for debug
        // this.parent.scene.add(this.mesh);

        this.pack.top.addResize(this, this.resizeHandler);
        this.resizeHandler();
        this.verticesLength = this.g.attributes.position.count;
    }

    //BufferGeometryの配列を動的に変更
    generateGeometryPosition(){
        let length = this.verticesCnt;
        // let length = this.g.attributes.position.count;
        let positions = new Float32Array( length * 3 ); // 3 vertices per point
        this.g.addAttribute( 'position', new THREE.BufferAttribute( positions, 3 ) );
        if(this.verticesCnt < this.verticesLength) this.verticesCnt += 6;
        if(this.verticesCnt > this.verticesLength) this.verticesCnt = this.verticesLength;
    }

    checkMerge(target){
        let flag = false;

        for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
            if( Math.abs( target.v.x - this.mergeVertices[i].list[0].v.x ) < 0.001 &&
                Math.abs( target.v.y - this.mergeVertices[i].list[0].v.y ) < 0.001 &&
                Math.abs( target.v.z - this.mergeVertices[i].list[0].v.z ) < 0.001)
            {
                this.mergeVertices[i].list.push( target );
                flag = true;
            }
        }

        if(!flag){
            let mergeVertex = new MergeVertex(this, [target]);
            this.mergeVertices.push(mergeVertex);
        }
    }

    start(){
        let ease = Quart.easeOut;
        this.parent.scene.add(this.mesh);
        let scale = this.scale * 1.2;

        gsap.killTweensOf(this.material);
        this.material.opacity = this.polygonOpacity;

        // gsap.killTweensOf(this.material.uniforms.alpha);
        // this.material.uniforms.alpha.value = .8;

        gsap.killTweensOf(this.mesh.scale);
        gsap.to(this.mesh.scale, .5, {x:scale, y:scale, z:scale, ease:Quart.easeOut});

        for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
            let v = this.mergeVertices[i];
            v.isTransit = true;
            v._currentX = 0;
            v._currentY = 0;
            v._currentZ = this.defVertexZ;
            gsap.killTweensOf(v);
            gsap.to(v, .3  , {delay:i * .01 + Math.random() * .3, keyframes:[{_currentX:0, _currentY:0, _currentZ:this.defVertexZ}, {_currentX:v._startX, _currentY:v._startY, _currentZ:v._startZ}], ease:ease, onComplete:function(){
                v.isTransit = false;
            }});
        }


        this.pack.top.removeEnterframe(this);
        this.pack.top.addEnterframe(this, this.enterframe);
    }

    stop(){
        let ease = Quad.easeOut;
        let sc = this;

        gsap.killTweensOf(this.material);
        gsap.to(this.material, .3, {delay:.5, opacity:0, ease:Quart.easeOut});

        // gsap.killTweensOf(this.material.uniforms.alpha);
        // gsap.to(this.material.uniforms.alpha, 1, {value:0, ease:Quart.easeOut});

        gsap.killTweensOf(this.mesh.scale);
        gsap.to(this.mesh.scale, 1, {x:this.scale, y:this.scale, z:this.scale, ease:Quart.easeOut, onUpdate:function(){
            // sc.enterframe();
        }, onComplete:function(){
            sc.parent.scene.remove(sc.mesh);
            sc.pack.mv.resetPreviousPolygonID();

            sc.pack.top.removeEnterframe(sc);
        }});

        let len = this.mergeVertices.length;
        let timeLag = .01;
        let totalDelay = len * timeLag;
        for( let i = 0; i < len; i++ ){
            let v = this.mergeVertices[i];
            v.isTransit = true;

            gsap.killTweensOf(v);
            gsap.to(v, .4, {delay:totalDelay - (i * timeLag), keyframes:[{_currentX:v._startX, _currentY:v._startY, _currentZ:v._startZ}, {_currentX:0, _currentY:0, _currentZ:this.defVertexZ}], ease:ease});
        }
    }

    enterframe(){
        this.enterframeIgnoreCnt++;

        //BufferGeometryの配列を動的に変更
        if(this.enterframeIgnoreCnt % 2 === 0) {
            for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
                let v = this.mergeVertices[i];
                if(v.isTransit) v.enterframeDuringTransition();
                else v.enterframe();
            }
        }

        this.material.needsUpdate = true;
        this.model.children[0].geometry.attributes.position.needsUpdate = true;

        // const time = this.clock.getElapsedTime();
        // this.material.uniforms.time.value = time;
    }

    resizeHandler(){
        this.sw = this.pack.top.sw;
        this.sh = this.pack.top.sh;

        let sh = this.sh / this.pack.mv.HEIGHT;
        this.scale = 125 * sh;
        // this.scale = 200 * sh;
        // this.scale = 1800 * sh;
        this.mesh.scale.set(this.scale,this.scale,this.scale);
        // this.material.uniforms.resolution.value = new THREE.Vector2(window.innerWidth, window.innerHeight);
    }
}


class Vertex{
    constructor(p, id, v){
        this.parent = p;
        this.id = id;
        this.v = v;
    }
    setPos(v){
        this.v.x = v.x;
        this.v.y = v.y;
        this.v.z = v.z;

        this.parent.g.attributes.position.array[this.id] = this.v.x;
        this.parent.g.attributes.position.array[this.id + 1] = this.v.y;
        this.parent.g.attributes.position.array[this.id + 2] = this.v.z;
    }
}

class MergeVertex{
    constructor(p, list){
        this.parent = p;
        this.list = list;
        this.conf = window.tokyo.pics.conf;
        this.isTransit = false;
        this.distanceFromZero;
    }
    init(){
        let list = this.list;

        this._startX = list[0].v.x;
        this._startY = list[0].v.y;
        this._startZ = Math.random() * 0.16 - 0.08;//list[0].v.z;

        this._endX = list[0].v.x + Math.random() * 0.06 - 0.03;
        this._endY = list[0].v.y;
        this._endZ = Math.random() * 0.16 - 0.08;//list[0].v.z;

        this._currentX = this._startX;
        this._currentY = this._startY;
        this._currentZ = this._startZ;

        this.distanceFromZero = Math.abs(this._startY);


        for( let i = 0, len = list.length; i < len; i++ ){
            let v = {};
            v.x = this._startX;
            v.y = this._startY;
            v.z = this._startZ;

            list[i].setPos(v);
        }
    }

    enterframe(){
        let conf = this.conf;

        this._currentX = (this._currentX * conf.s096a) + (this._endX * conf.s096b);
        this._currentY = (this._currentY * conf.s096a) + (this._endY * conf.s096b);
        this._currentZ = (this._currentZ * conf.s096a) + (this._endZ * conf.s096b);


        for( let i = 0; i < this.list.length; i++ )
        {
            let v = {};
            v.x = this._currentX;
            v.y = this._currentY;
            v.z = this._currentZ;
            this.list[i].setPos( v );
        }

        if( Math.abs( this._currentZ - this._endZ ) < 0.01 )
        {
            this._endX = this._startX + Math.random() * 0.4 - 0.2;
            this._endY = this._startY + Math.random() * 0.4 - 0.2;
            // this._endZ = this._startZ + Math.random() * 0.4 - 0.2;
            this._endZ = Math.random() * 0.4 - 0.2;
        }
    }

    enterframeDuringTransition(){
        for( let i = 0; i < this.list.length; i++ )
        {
            let v = {};
            v.x = this._currentX;
            v.y = this._currentY;
            v.z = this._currentZ;
            this.list[i].setPos( v );
        }
    }
}
