import EventDispatcher from './libs/daijima/EventDispatcher';
// global.THREE = require('./libs/three/three.module');
global.THREE = require('./libs/three/three.min');
require('./libs/three/loaders/OBJLoader');
require('./libs/three/loaders/MTLLoader');
import "@babel/polyfill";
import VideoManager from './VideoManager';
import Background from './Background';
import BlendScene from './BlendScene';
import Noise from './Noise';
import Sandstorm from './Sandstorm';

import Polygon from './Polygon';
import WireframePolygon from './WireframePolygon';
import LinePolygon from './LinePolygon';
import BoxPolygon from './BoxPolygon';
import LiquidPolygon from './LiquidPolygon';
import SandstormPlane from './SandstormPlane';

// import * as dat from 'dat.gui';

require('./libs/three/postprocessing/EffectComposer');
require('./libs/three/postprocessing/RenderPass');
require('./libs/three/postprocessing/ShaderPass');
require('./libs/three/shaders/CopyShader');
require('./libs/three/shaders/FXAAShader');

export default class extends EventDispatcher {
    /*
    0 Polygon
    1 WirePolygon
    2 LinePolygon
    3 BoxPolygon
    4 LiquidPolygon

     */
    constructor() {
        super();
        // this.setDatGUI();
        this.initialise();
    }

/*
    setDatGUI(){
        let sc = this;

        const PROPERTY = {
            'directionLightX':0,
            'directionLightY':0,
            'directionLightZ':1,
            'directionLightColor':'#ffffff',
            'ambientLightColor':'#cccccc'
        };

        this.gui = new dat.GUI();

        this.gui.add(PROPERTY, 'directionLightX', 0, 1000).onChange(function(){
            sc.directional_light.position.x = PROPERTY.directionLightX;
        });

        this.gui.add(PROPERTY, 'directionLightY', 0, 1000).onChange(function(){
            sc.directional_light.position.y = PROPERTY.directionLightY;
        });

        this.gui.add(PROPERTY, 'directionLightZ', 0, 1000).onChange(function(){
            sc.directional_light.position.z = PROPERTY.directionLightZ;
        });

        this.gui.addColor(PROPERTY,'directionLightColor').onChange(function(){
            sc.directional_light.color.set(PROPERTY.directionLightColor);
        });

        this.gui.addColor(PROPERTY,'ambientLightColor').onChange(function(){
            sc.ambient_light.color.set(PROPERTY.ambientLightColor);
        });
    }
*/

    initialise() {
        this.pack = window.tokyo.pics;

        this.WIDTH = 1400;
        this.HEIGHT = 787;

        this.devicePixelRatio = window.devicePixelRatio || 1;
        this.devicePixelRatio = Math.min(2, this.devicePixelRatio);

        this.canvasContainer = document.querySelector('#canvasContainer');
        this.canvas = this.canvasContainer.querySelector('canvas');
        // this.width = this.canvasContainer.offsetWidth;
        // this.height = this.canvasContainer.offsetHeight;
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.canvas.width = this.width;
        this.canvas.height = this.height;

        this.loadCnt = 0;
        this.completedLoad = false;
        this.needsLoadLength = 5;       //Polygon, WireframePolygon, BoxPolygon, LiquidPolygon, VideoManager
        this.polygonLength = 5;
        this.currentPolygonID;
        this.previousPolygonID;
        this.pastPolygonID;

        this.rotationInterval;

        this.isOver = false;
        this.polygon, this.wire_polygon, this.line_polygon, this.bg, this.blendScene, this.noise;

        this.polygon = new Polygon(this);
        this.polygon.addEventListener("load", this.loadDataHandler.bind(this));

        this.wire_polygon = new WireframePolygon(this);
        this.wire_polygon.addEventListener("load", this.loadDataHandler.bind(this));

        this.box_polygon = new BoxPolygon(this);
        this.box_polygon.addEventListener("load", this.loadDataHandler.bind(this));

        this.liquid_polygon = new LiquidPolygon(this);
        this.liquid_polygon.addEventListener("load", this.loadDataHandler.bind(this));

        this.sandstorm_plane = new SandstormPlane(this);

        this.vm = new VideoManager();
    }

    loadDataHandler(event){
        this.loadCnt++;
        if(this.loadCnt === this.needsLoadLength) this.loadHandler();
    }

    loadHandler(){
        this.completedLoad = true;
        this.line_polygon = new LinePolygon(this);

        this.initShader();
        this.initWebGL();
        this.initBackgound();
        this.initMesh();
        this.initEvents();
        this.resizeHandler();

        this.noise.contentsStart();

        this.blendScene.contentsStart();
        this.bg.contentsStart();

        this.pack.top.addEnterframe(this, this.enterframe);

        if(this.pack.hasOpeningMotion) this.pack.top.hideLoading();
        else this.pack.top.skipLoading();
    }

    initEvents() {
        this.canvas.addEventListener('mousemove', this.mouseMoveHandler.bind(this));
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler(){
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;

        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
        if(this.width > this.pack.BP){
            this.stopRotation();
        }else{
            this.startRotation();
        }
    }

    startRotation(){
        let sc = this;
        if(this.rotationInterval) clearInterval(this.rotationInterval);
        this.rotationInterval = setInterval(function(){
            let new_id = Math.floor(Math.random() * sc.polygonLength);
            if(sc.isOver) sc.out();
            else sc.executeOver(new_id);
        }, 5000);
    }

    stopRotation(){
        if(this.rotationInterval) clearInterval(this.rotationInterval);
    }

    mouseMoveHandler(event){
        this.mx = event.offsetX;
        this.my = event.offsetY;
    }

    initShader() {
        this.vertexShaderSrc = `
        
        `;

        this.fragmentShaderSrc = `
        
        `;
    }

    initWebGL() {
        this.canvas = document.querySelector("#canvas");

        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : false
            // antialias : true
        });

        this.renderer.setClearColor( 0x000000, 0);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.width, this.height);
        // this.renderer.autoClear = false;

        this.scene = new THREE.Scene();

        this.ambient_light = new THREE.AmbientLight(0xcccccc, 1);
        // this.ambient_light = new THREE.AmbientLight(0xaaaaaa, 1);
        this.scene.add(this.ambient_light);

        this.directional_light = new THREE.DirectionalLight(0xffffff, 1);
        this.directional_light.position.z = 1;
        this.scene.add(this.directional_light);

        this.fov = 45;
        // this.defCameraZ = this.height / Math.tan(this.fov * Math.PI / 360) / 2;
        // this.cameraZ = this.defCameraZ;
        this.camera = new THREE.PerspectiveCamera(this.fov, this.width / this.height, 0.1, 3000);
        // this.camera.position.z = this.defCameraZ;
        this.camera.position.z = 1000;
    }

    initBackgound(){
        this.blendScene = new BlendScene();
        this.bg = new Background();
        this.noise = new Noise();
        this.sandstorm = new Sandstorm();
    }

    initMesh(){
        this.polygon.initMesh();
        this.wire_polygon.initMesh();
        this.line_polygon.initMesh();
        this.box_polygon.initMesh();
        this.liquid_polygon.initMesh();
        this.sandstorm_plane.initMesh();
    }

    changePolygon(){
        if(this.currentPolygonID === 0) {
            this.polygon.start();
            this.currentPolygon = this.polygon;
        }else if(this.currentPolygonID === 1) {
            this.wire_polygon.start();
            this.currentPolygon = this.wire_polygon;
        }else if(this.currentPolygonID === 2) {
            this.line_polygon.start();
            this.currentPolygon = this.line_polygon;
        }else if(this.currentPolygonID === 3) {
            this.box_polygon.start();
            this.currentPolygon = this.box_polygon;
        }else if(this.currentPolygonID === 4) {
            this.liquid_polygon.start();
            this.currentPolygon = this.liquid_polygon;
        }

        this.sandstorm_plane.start();
    }

    executeOver(new_id){
        this.currentPolygonID = this.pastPolygonID = new_id;
        this.bg.over();
        this.blendScene.bgLogo.over();
        this.changePolygon();
        this.isOver = true;
    }

    over(id){
        if(!this.completedLoad) return;
        this.out();
        let new_id = Math.floor(Math.random() * this.polygonLength);

        // if(new_id === this.pastPolygonID || (new_id === 3 && this.pastPolygonID === 1) || (new_id === 4 && this.pastPolygonID === 1)){
        if(new_id === this.pastPolygonID){
            this.over(id);
            return;
        }

        //for debug
        // new_id = 4;

        this.executeOver(new_id);
    }

    out(id){
        if(!this.completedLoad || !this.isOver) return;
        this.bg.out();
        this.blendScene.bgLogo.out();
        this.currentPolygon.stop();
        this.previousPolygonID = this.currentPolygonID;
        this.currentPolygonID = -1;
        this.isOver = false;

        this.sandstorm_plane.stop();
    }

    resetPreviousPolygonID(){
        this.previousPolygonID = -1;
    }

    enterframe(){
        this.render();
    }

    render(){
        this.sw = window.innerWidth;
        this.sh = window.innerHeight;


        this.camera.aspect = this.sw / this.sh;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize( this.sw, this.sh );

        let cameraZ = this.sh / Math.tan(this.fov * Math.PI / 360) / 2;
        this.camera.position.set(0, 0, cameraZ);

        // this.camera.lookAt(new THREE.Vector3(0, 0, 0));
        this.renderer.render(this.scene, this.camera);

    }
}


