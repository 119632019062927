import "@babel/polyfill";

require('./libs/daijima/trace.js');
require('./libs/daijima/requestanimationframe.js');
require('./top.js');

// import "../scss/common.scss";
// import "../scss/top.scss";

//CSS Modulesとして使用する場合
// import * as styles from "../scss/common.scss";

