import BackgroundLogo from "./BackgroundLogo";

export default class{
    constructor(){
        this.pack = window.tokyo.pics;

        this.init();
    }

    init(){
        this.initScene();
    }

    initScene(){
        this.width = 1400;
        this.height = 787;

        this.scene = new THREE.Scene();

        this.setViewPort();
        this.camera = new THREE.OrthographicCamera(
            this.viewPort.left,
            this.viewPort.right,
            this.viewPort.top,
            this.viewPort.bottom,
            this.viewPort.near,
            this.viewPort.far
        );


        this.camera.position.set( 0, 0, 1000 );

        this.renderTarget = new THREE.WebGLRenderTarget( this.width, this.height,
        {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter,
            wrapS: THREE.ClampToEdgeWrapping,
            wrapT: THREE.ClampToEdgeWrapping,
            format: THREE.RGBAFormat,
        });

        this.bgLogo = new BackgroundLogo(this);


    }

    setViewPort() {
        this.viewPort = {};

        var w = this.width;
        var h = this.height;
        var viewSize = h;
        var aspectRatio = w / h;

        this.viewPort = {
            viewSize: viewSize,
            aspectRatio: aspectRatio,
            left: (-aspectRatio * viewSize) / 2,
            right: (aspectRatio * viewSize) / 2,
            top: viewSize / 2,
            bottom: -viewSize / 2,
            near: 0,
        }
    }

    contentsStart(){
        this.bgLogo.contentsStart();
        this.pack.top.addEnterframe(this, this.enterframe);
    }

    enterframe(){
        // trace("enterframe", this.pack.mv.renderer, this.scene, this.camera, this.renderTarget);
        // this.pack.mv.renderer.setRenderTarget(this.renderTarget);
        this.pack.mv.renderer.render( this.scene, this.camera, this.renderTarget );
    }
}