export default class {
    constructor() {
        this.pack = window.tokyo.pics;
        this.pack.top.addEnterframe(this, this.enterframe);

        this._count = 0;
        this._oldTime = 0;
        this._d = 1.0;
        this._nd;
    }

    enterframe(){
        let _newTime = Date.now();
        let _diff = ( _newTime - this._oldTime) / 1000;
        let _nd = _diff / 0.016;

        if( _nd >= 3 ){ _nd = 3; }

        this._nd = _nd;

        this._d = ( this._d * 0.8 ) + ( _nd * 0.2 );

        this.s096a = 1 - 0.04 * this._d;
        this.s096b = 1 - this.s096a;

    }
}