import EventDispatcher from './libs/daijima/EventDispatcher';
import {gsap, Quart, Quad} from "gsap";

export default class extends EventDispatcher {
    constructor(parent) {
        super();

        let sc = this;
        this.pack = window.tokyo.pics;
        this.parent = parent;
        this.imgPath = "img/webgl/liquid2.png";

        Promise.all([this.loadTexture()]).then(function(){
            sc.loadHandler();
        });
    }

    loadTexture(){
        let sc = this;
        let loader = new THREE.TextureLoader();


        return new Promise((resolve) => {

            loader.load(
                this.pack.PATH + this.imgPath,
                function ( texture ) {
                    sc.texture = texture;
                    sc.texture.wrapS = sc.texture.wrapT = THREE.RepeatWrapping;
                    sc.texture.minFilter = THREE.LinearFilter;
                    sc.texture.magFilter = THREE.LinearFilter;
                    sc.texture.needsUpdate = true;

                    return resolve();
                },

                // onProgress callback currently not supported
                undefined,

                // onError callback
                function ( err ) {
                    console.error( 'An error happened. at LiquidPolygon' );
                }

            );
        });
    }

    loadHandler(){
        this.dispatchEvent("load");
    }

    initShader(){
        this.vertexShaderSrc = `
            varying vec2 vUv;
            
            void main()
            {
                vUv = uv;
                
                vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );	
                gl_Position = projectionMatrix * mvPosition;
            }
        `;

        this.framgmentShaderSrc = `
            uniform sampler2D texture;
            uniform sampler2D noise;
            
            //変化の時間
            uniform float time;
            
            //変化の強さ
            uniform float strength;
            
            //UVのスクロール値
            uniform float uvShift;
            
            //歪みの大きさ
            uniform float size;
            
            //透明度
            uniform float alpha;
            
            //UV y透明度------------追加
            uniform float alphaY;
            
            
            varying vec2 vUv;
            
            void main()
            {
                //生成したシンプレックスノイズ(0.7はノイズのサイズ調整で適当です)
                vec4 noiseCol = texture2D( noise, vUv * 0.7 );
                
                //UVスクロール
                vec2 sUv = vec2( vUv.x, vUv.y + uvShift );
                
                //シンプレックスノイズを元にUVを変化
                float mfSin = sin(noiseCol.r);
                vec2 effectUv = sUv + strength * vec2(mfSin, mfSin);
                
                //さらにUVを歪ませます
                vec2 p = -1.0 + 2.0 * effectUv;
                vec2 newUv = effectUv + ( strength * 0.5 ) * vec2( sin(-time + length( p * size )), sin(-time + length( p * size )) );
                
                
                ///////////////////////////////////////////////
                //追加
                //UV値でアルファ変化
                float ax = 1.0 - abs(vUv.x - 0.5) * 2.0;
                float ay = min( alphaY - abs(vUv.y - 0.5) * 2.0, 1.0 );
                ///////////////////////////////////////////////
                
                //最終的なカラー
                //テクスチャの色にシンプレックスノイズの色を引いて変化をつけています
                vec4 col1 = texture2D( texture, newUv );
                //gl_FragColor = vec4( col1.rgb - ( noiseCol.r * 0.5 ), col1.a * alpha );
                gl_FragColor = vec4( col1.rgb - ( noiseCol.r * 0.5 ), col1.a * alpha * ax * ay );//-----変更
                
            }        
        `;

    }

    initMesh(){
        //透明度
        this.alpha = 0.8;
        this.alphaY = 0;

        //歪み強さ
        this.strength = 0.55;//0.03;

        //経過時間
        this.time = 0;

        //歪みおおきさ
        this.size = 6;

        //スクロール値
        this.uvShift = 0;

        this.initShader();

        this.shader = {
            vertexShader: this.vertexShaderSrc,
            fragmentShader: this.framgmentShaderSrc,
            uniforms:
                {
                    texture:{ value: this.texture },
                    noise:{ value: this.pack.mv.noise.renderTarget.texture },
                    alpha:{ value:this.alpha },
                    alphaY:{ value:this.alphaY },
                    strength:{ value:this.strength },
                    time:{ value:this.time },
                    size:{ value:this.size },
                    uvShift:{ value:this.uvShift },
                },
            transparent:true,
        };

        this.material = new THREE.ShaderMaterial( this.shader );

        this.g = new THREE.PlaneGeometry(560, 900, 1, 2);

        this.g.vertices[0].x -= 50 - 30;
        this.g.vertices[1].x -= 50 - 30;

        this.g.vertices[2].x += 100;
        this.g.vertices[3].x += 100;

        this.g.vertices[4].x -= 50 - 30;
        this.g.vertices[5].x -= 50 - 30;

        this.g.verticesNeedUpdate = true;


        this.mesh = new THREE.Mesh(this.g, this.material);
        this.mesh.position.z = 100;


        this.pack.top.addResize(this, this.resizeHandler);
        this.resizeHandler();
    }

    start(){
        let sc = this;
        let scale = this.scale * 1.2;
        gsap.killTweensOf(this.mesh.scale);
        gsap.to(this.mesh.scale, .5, {x:scale, y:scale, z:scale, ease:Quart.easeOut});

        gsap.killTweensOf(this);
        gsap.to(this, .5, {alpha:0.8, ease:Quad.easeOut, onUpdate:function(){
            sc.shader.uniforms.alpha.value = sc.alpha;
        }});
        // this.shader.uniforms.alpha.value = this.alpha;
        this.parent.scene.add(this.mesh);

        this.pack.top.removeEnterframe(this);
        this.pack.top.addEnterframe(this, this.startFrame);
    }

    stop(){
        let sc = this;
        sc.pack.top.removeEnterframe(sc);
        this.pack.top.addEnterframe(this, this.stopFrame);

        gsap.killTweensOf(this.mesh.scale);
        gsap.to(this.mesh.scale, .5, {x:this.scale, y:this.scale, z:this.scale, ease:Quart.easeOut});

        gsap.killTweensOf(this);
        gsap.to(this, .8, {alpha:0, ease:Quad.easeOut, onUpdate:function(){
            sc.shader.uniforms.alpha.value = sc.alpha;
        }, onComplete:function(){
            // sc.parent.scene.remove(sc.mesh);
            // sc.pack.top.removeEnterframe(sc);
        }});
    }

    enterframe(){
/*        this.time += 0.05;
        this.shader.uniforms.time.value = this.time;

        this.uvShift += 0.0035;
        this.shader.uniforms.uvShift.value = this.uvShift;*/
    }

    startFrame(){
        // trace("startFrame");
        this.time += 0.05;
        this.shader.uniforms.time.value = this.time;

        this.uvShift += 0.0035;
        this.shader.uniforms.uvShift.value = this.uvShift;

        //0.55～0.07へ変化
        this.strength = (this.strength * 0.94) + (0.07 * 0.06);
        this.shader.uniforms.strength.value = this.strength;

        //this.alpha = (this.alpha * 0.9) + (0.8 * 0.1);
        //this.shader.uniforms.alpha.value = this.alpha;

        //uvを使用した透明度にしてます。0～2に変化
        this.alphaY = (this.alphaY * 0.96) + (2 * 0.04);
        this.shader.uniforms.alphaY.value = this.alphaY;
    }

    stopFrame(){
        // trace("stopFrame");
        this.time += 0.05;
        this.shader.uniforms.time.value = this.time;

        this.uvShift += 0.0035;
        this.shader.uniforms.uvShift.value = this.uvShift;

        //0.07～0.55へ変化
        this.strength = (this.strength * 0.94) + (0.55 * 0.06);
        this.shader.uniforms.strength.value = this.strength;

        //this.alpha = (this.alpha * 0.9) + (0 * 0.1);
        //this.shader.uniforms.alpha.value = this.alpha;

        //uvを使用した透明度にしてます。2～0に変化
        this.alphaY = (this.alphaY * 0.94) + (0 * 0.06);
        this.shader.uniforms.alphaY.value = this.alphaY;

        if( this.alphaY < 0.001 )
        {
            this.parent.scene.remove(this.mesh);
            this.pack.top.removeEnterframe(this);

            this.alphaY = 0;
            this.shader.uniforms.alphaY.value = this.alphaY;
        }
    }

    resizeHandler(){
        this.sw = this.pack.top.sw;
        this.sh = this.pack.top.sh;

        let scale = this.scale = this.sh / 900;
        this.mesh.scale.x = this.mesh.scale.y = scale;
    }
}
