import EventDispatcher from './libs/daijima/EventDispatcher';
import {gsap, Expo, Quart} from "gsap";

export default class extends EventDispatcher {
    constructor(parent){
        super();

        let sc = this;
        this.pack = window.tokyo.pics;
        this.parent = parent;
        this.objPath = "models/extruded_random_logo_dense6.obj";
        this.dotPath = "img/webgl/dot.png";                           //Dot画像
        this.model;
        this.particleTexture;
        this.defOpacity = .5;
        this.defScale = 100;
        this.enterframeIgnoreCnt = 0;

        Promise.all([this.loadTexture(this.pack.PATH + this.dotPath), this.loadOBJ(this.pack.PATH + this.objPath)]).then(function(){
            sc.loadHandler();
        });
    }

    loadTexture(path){
        let sc = this;
        let loader = new THREE.TextureLoader();
        return new Promise((resolve) => {
            loader.load(
                // resource URL
                path,

                // onLoad callback
                function ( texture ) {
                    // in this example we create the material when the texture is loaded
                    sc.particleTexture = texture;

                    return resolve();
                },

                // onProgress callback currently not supported
                undefined,

                // onError callback
                function ( err ) {
                    console.error( 'An error happened. at loadTexture, WireframePolygon' );
                }
            );
        });
    }

    loadOBJ(path) {
        let sc = this;
        let loader = new THREE.OBJLoader();

        return new Promise((resolve) => {
            loader.load(
                path,
                function ( object ) {
                    sc.model = object;
                    return resolve();
                },
                function ( xhr ) {
                    // trace( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                },
                function ( error ) {
                    console.error( 'An error happened. at loadOBJ, WireframePolygon' );
                }
            );
        });
    }

    loadHandler(){
        this.dispatchEvent("load");
    }

    initMesh(){
        this.material = new THREE.MeshStandardMaterial({
        // this.material = new THREE.MeshPhongMaterial({
            color: 0xffffff,
            // map:this.pack.commonTexture,
            emissive: 0x000000,
            // roughness:1,
            // metalness:0.5,
            wireframe: true,
            // blending: THREE.AdditiveBlending,
            // blending: THREE.MultiplyBlending,
            // side:THREE.DoubleSide,
            transparent:true,
            opacity:this.defOpacity
        });
        this.material.needsUpdate = true;

        let g = this.g = this.model.children[0].geometry;
        let g_array = g.attributes.position.array;
        let g_array_length = g_array.length;
        let vertices = this.defVertices = [];

        for( let i = 0; i < g_array_length; i+=3 ){
            let v = {};
            v.x = g_array[i];
            v.y = g_array[i+1];
            v.z = g_array[i+2];
            let v_obj = new Vertex(this, this.g, i, v);
            vertices.push(v_obj);
        }

        this.mergeVertices = [];
        let mergeVertex = new MergeVertex(this, [vertices[0]]);
        this.mergeVertices.push(mergeVertex);
        for( let i = 1, len = vertices.length; i < len; i++ ){
            this.checkMerge(vertices[i]);
        }

        for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
            let mergeVertex = this.mergeVertices[i];
            mergeVertex.init();
        }

        //頂点のY軸でソートする
        this.mergeVertices.sort(function(a,b){
            // if(a._startY > b._startY) return -1;
            if(a.distanceFromZero < b.distanceFromZero) return -1;
            if(a.distanceFromZero > b.distanceFromZero) return 1;
            return 0;
        });

        // this.model.material = this.material;
        this.mesh = new THREE.Mesh(this.g, this.material);

        let commonZ = 100;
        this.mesh.scale.set(this.defScale,this.defScale,this.defScale);
        // this.mesh.position.z = 100;
        this.mesh.position.z = commonZ;

        let particle_vertices = [];
        for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
            let m = this.mergeVertices[i];
            let v = m.list[0].v;
            particle_vertices.push( new THREE.Vector3(v.x, v.y, v.z));
        }

        this.particle_g = new THREE.BufferGeometry().setFromPoints( particle_vertices );
        this.particle_m = new THREE.PointsMaterial({
            size:9,
            map:this.particleTexture,
            depthTest:false,
            depthWrite:false,
            transparent:true,
            opacity: this.defOpacity
        });

        this.particle_mesh = new THREE.Points( this.particle_g, this.particle_m );
        this.particle_mesh.scale.set(93,93,93);
        // this.particle_mesh.position.z = 100;
        this.particle_mesh.position.z = commonZ;

        this.pack.top.addResize(this, this.resizeHandler);
        this.resizeHandler();
    }

    checkMerge(target){
        let flag = false;

        for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
            if( Math.abs( target.v.x - this.mergeVertices[i].list[0].v.x ) < 0.001 &&
                Math.abs( target.v.y - this.mergeVertices[i].list[0].v.y ) < 0.001 &&
                Math.abs( target.v.z - this.mergeVertices[i].list[0].v.z ) < 0.001)
            {
                this.mergeVertices[i].list.push( target );
                flag = true;
            }
        }

        if(!flag){
            let mergeVertex = new MergeVertex(this, [target]);
            this.mergeVertices.push(mergeVertex);
        }
    }

    start(){
        let ease = Quart.easeOut;
        this.parent.scene.add(this.mesh);
        this.parent.scene.add(this.particle_mesh);

        let scale = this.scale * 1.2;
        gsap.killTweensOf(this.mesh.scale);
        gsap.killTweensOf(this.particle_mesh.scale);
        gsap.killTweensOf(this.material);
        gsap.killTweensOf(this.particle_m);

        this.material.opacity = this.defOpacity;
        this.particle_m.opacity = 0;
        gsap.to(this.particle_m, .2, {delay:.7, opacity:this.defOpacity, ease:Quart.easeOut});
        gsap.to(this.mesh.scale, .5, {x:scale, y:scale, z:scale, ease:Quart.easeOut});
        gsap.to(this.particle_mesh.scale, .5, {x:scale, y:scale, z:scale, ease:Quart.easeOut});

        for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
            let v = this.mergeVertices[i];
            v.isTransit = true;
            v._currentX = v._startY * .7;
            v._currentY = v._startY * .7;
            v._currentZ = -10;
            gsap.killTweensOf(v);

            gsap.to(v, .3, {delay:i * .008 + Math.random() * .3, keyframes:[{_currentX:v._startX + Math.random() * .2 - .1, _currentY:v._startY + Math.random() * .2 - .1, _currentZ:0}, {_currentX:v._startX, _currentY:v._startY, _currentZ:v._startZ}], ease:ease, onComplete:function(){
                v.isTransit = false;
            }});
        }

        this.pack.top.removeEnterframe(this);
        this.pack.top.addEnterframe(this, this.enterframe);
    }

    stop(){
        let ease = Expo.easeOut;
        let sc = this;

        gsap.killTweensOf(this.mesh.scale);
        gsap.killTweensOf(this.particle_mesh.scale);
        gsap.killTweensOf(this.material);
        gsap.killTweensOf(this.particle_m);


        gsap.to(this.material, .3, {delay:.5, opacity:0, ease:Quart.easeOut});
        gsap.to(this.particle_m, .3, {opacity:0, ease:Quart.easeOut});

        gsap.to(this.mesh.scale, .8, {x:this.scale, y:this.scale, z:this.scale, ease:Quart.easeOut});
        gsap.to(this.particle_mesh.scale, .8, {x:this.scale, y:this.scale, z:this.scale, ease:Quart.easeOut, onComplete:function(){
            sc.parent.scene.remove(sc.mesh);
            sc.parent.scene.remove(sc.particle_mesh);
            sc.pack.mv.resetPreviousPolygonID();

            sc.pack.top.removeEnterframe(sc);
        }});

        let len = this.mergeVertices.length;
        let timeLag = .005;
        let totalDelay = len * timeLag;
        for( let i = 0; i < len; i++ ){
            let v = this.mergeVertices[i];
            v.isTransit = true;
            gsap.killTweensOf(v);
            gsap.to(v, .3, {delay:totalDelay - (i * timeLag), keyframes:[{_currentX:v._startX + Math.random() * .2 - .1, _currentY:v._startY + Math.random() * .2 - .1, _currentZ:0}, {_currentX:v._startX * .7, _currentY:v._startY * .7, _currentZ:-10}], ease:ease});
        }
    }

    enterframe(){
        this.enterframeIgnoreCnt++;

        if(this.enterframeIgnoreCnt % 2 === 0) {
            for( let i = 0, len = this.mergeVertices.length; i < len; i++ ){
                let v = this.mergeVertices[i];
                if(v.isTransit) v.enterframeDuringTransition();
                else v.enterframe();

                //DotParticle
                let mv = v.list[0].v;
                this.particle_g.attributes.position.setX(i, mv.x);
                this.particle_g.attributes.position.setY(i, mv.y);
                this.particle_g.attributes.position.setZ(i, mv.z);
            }
        }

        this.model.children[0].geometry.attributes.position.needsUpdate = true;
        this.particle_g.attributes.position.needsUpdate = true;
    }

    resizeHandler(){
        this.sw = this.pack.top.sw;
        this.sh = this.pack.top.sh;

        let sh = this.sh / this.pack.mv.HEIGHT;
        // this.scale = 125 * sh;
        this.scale = this.defScale * sh;
        this.mesh.scale.set(this.scale,this.scale,this.scale);
        this.particle_mesh.scale.set(this.scale,this.scale,this.scale);

    }
}


class Vertex{
    constructor(p, g, id, v){
        this.parent = p;
        this.g = g;
        this.id = id;
        this.v = v;
    }
    setPos(v){
        this.v.x = v.x;
        this.v.y = v.y;
        this.v.z = v.z;

        this.g.attributes.position.array[this.id] = this.v.x;
        this.g.attributes.position.array[this.id + 1] = this.v.y;
        this.g.attributes.position.array[this.id + 2] = this.v.z;
    }
}

class MergeVertex{
    constructor(p, list){
        this.parent = p;
        this.list = list;
        this.conf = window.tokyo.pics.conf;
        this.isTransit = false;
        this.distanceFromZero;
    }
    init(){
        let list = this.list;

        this._startX = list[0].v.x;
        this._startY = list[0].v.y;
        this._startZ = list[0].v.z;

        this._endX = list[0].v.x + Math.random() * 0.06 - 0.03;
        this._endY = list[0].v.y;
        this._endZ = list[0].v.z;

        this._currentX = this._startX;
        this._currentY = this._startY;
        this._currentZ = this._startZ;

        this.distanceFromZero = Math.abs(this._startY);


        for( let i = 0, len = list.length; i < len; i++ ){
            let v = {};
            v.x = this._startX;
            v.y = this._startY;
            v.z = this._startZ;

            list[i].setPos(v);
        }
    }
    enterframe(){
        let conf = this.conf;

        this._currentX = (this._currentX * conf.s096a) + (this._endX * conf.s096b);
        this._currentY = (this._currentY * conf.s096a) + (this._endY * conf.s096b);
        this._currentZ = (this._currentZ * conf.s096a) + (this._endZ * conf.s096b);

        for( let i = 0; i < this.list.length; i++ )
        {
            let v = {};
            v.x = this._currentX;
            v.y = this._currentY;
            v.z = this._currentZ;
            this.list[i].setPos( v );
        }

        if( Math.abs( this._currentZ - this._endZ ) < 0.01 )
        {
            this._endX = this._startX + Math.random() * 0.4 - 0.2;
            this._endY = this._startY + Math.random() * 0.4 - 0.2;
            this._endZ = this._startZ + Math.random() * 0.4 - 0.2;
        }
    }

    enterframeDuringTransition(){
        for( let i = 0; i < this.list.length; i++ )
        {
            let v = {};
            v.x = this._currentX;
            v.y = this._currentY;
            v.z = this._currentZ;
            this.list[i].setPos( v );
        }
    }
}
