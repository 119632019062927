import {gsap, Quart, Quad} from "gsap";

export default class{
    constructor() {
        this.pack = window.tokyo.pics;
        this.init();
    }
    init(){
        this.width = 1400;
        this.height = 787;
        this.g = new THREE.PlaneGeometry(this.width, this.height, 2, 2);

        this.darkMax = 0.45;
        this.darkMin = 0.8;
        this.dark = this.darkMax;

        this.shiftX = 0;
        this.shiftY = 0;


        this.shader = {
            vertexShader: `
                varying vec2 vUv;
                
                void main()
                {
                    vUv = uv;
                    
                    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );	
                    gl_Position = projectionMatrix * mvPosition;
                }
            `,
            fragmentShader: `
                uniform sampler2D texture;
                uniform sampler2D mark;
                uniform float dark;
                
                uniform float shiftX;
                uniform float shiftY;
                
                varying vec2 vUv;
                
                void main()
                {
                    vec4 markCol = texture2D( mark, vUv );
                    
                    
                    vec2 onePixel = vec2(1.0, 1.0) / vec2(1400.0, 787.0);
                    float n1 = -0.170;//-0.176;//-0.18;//-0.2;
                    float n2 = 2.30;//2.37;//2.4;//2.9;
                    vec4 sharpCol =
                        texture2D(texture, vUv + onePixel * vec2(-1, -1)) * n1 +
                        texture2D(texture, vUv + onePixel * vec2( 0, -1)) * n1 +
                        texture2D(texture, vUv + onePixel * vec2( 1, -1)) * n1 +
                        texture2D(texture, vUv + onePixel * vec2(-1,  0)) * n1 +
                        texture2D(texture, vUv + onePixel * vec2( 0,  0)) * n2 +
                        texture2D(texture, vUv + onePixel * vec2( 1,  0)) * n1 +
                        texture2D(texture, vUv + onePixel * vec2(-1,  1)) * n1 +
                        texture2D(texture, vUv + onePixel * vec2( 0,  1)) * n1 +
                        texture2D(texture, vUv + onePixel * vec2( 1,  1)) * n1 ;
                    
                    
                    ////////////////////////
                    vec2 shiftUv = vec2( vUv.x + shiftX, vUv.y + shiftY );
                    vec4 sharpCol2 =
                        texture2D(texture, shiftUv + onePixel * vec2(-1, -1)) * n1 +
                        texture2D(texture, shiftUv + onePixel * vec2( 0, -1)) * n1 +
                        texture2D(texture, shiftUv + onePixel * vec2( 1, -1)) * n1 +
                        texture2D(texture, shiftUv + onePixel * vec2(-1,  0)) * n1 +
                        texture2D(texture, shiftUv + onePixel * vec2( 0,  0)) * n2 +
                        texture2D(texture, shiftUv + onePixel * vec2( 1,  0)) * n1 +
                        texture2D(texture, shiftUv + onePixel * vec2(-1,  1)) * n1 +
                        texture2D(texture, shiftUv + onePixel * vec2( 0,  1)) * n1 +
                        texture2D(texture, shiftUv + onePixel * vec2( 1,  1)) * n1 ;
                    ///////////////////////
                    
                    
                    vec3 fCol1 = mix(sharpCol.rgb, vec3(0.0, 0.0, 0.0), dark + (markCol.g * 0.18));
                    vec3 fCol2 = sharpCol2.rgb * 1.2 + 0.10;
                    
                    vec3 final = mix( fCol1, fCol2, markCol.r );
                    gl_FragColor = vec4( final, 1.0 );
                    
                    
                    /*if( markCol.r < 1.0 )
                    {
                        vec3 mixCol = mix(sharpCol.rgb, vec3(0.0, 0.0, 0.0), 0.35);
                        gl_FragColor = vec4( mixCol, 1.0 );
                    }
                    else
                    {
                        gl_FragColor = vec4( sharpCol2.rgb * 1.3 + 0.15, 1.0 );
                    }*/
                    
                    //gl_FragColor = vec4( sharpCol.rgb, alpha );
                    
                }
            `,
            uniforms:
                {
                    texture:{ value: null },
                    mark:{value:null},
                    dark:{value:this.dark},
                    shiftX:{value:this.shiftX},
                    shiftY:{value:this.shiftY},
                },
            transparent:true,
        };

        this.m = new THREE.ShaderMaterial( this.shader );

/*        this.m = new THREE.MeshLambertMaterial({
            color:0xffffff,
            transparent:true,
            side:THREE.DoubleSide
        });*/
        this.mesh = new THREE.Mesh(this.g, this.m);
        this.pack.mv.scene.add(this.mesh);
        this.pack.top.addResize(this, this.resizeHandler);
        this.resizeHandler();
    }

    contentsStart(){
        this.pack.mv.vm.play(1);
        this.shader.uniforms.texture.value = this.pack.mv.vm.getTexture(1);
        this.shader.uniforms.mark.value = this.pack.mv.blendScene.renderTarget.texture;
        // this.shader.uniforms.mark.value = _main._world._dummy._render.texture;


        this.pack.top.removeEnterframe(this);
        this.pack.top.addEnterframe(this, this.enterframe);
    }

    enterframe(){
        // this.shader.uniforms.texture.value = this.pack.mv.vm.getTexture(1);
    }

    out(){
        gsap.killTweensOf(this.shader.uniforms.dark);
        gsap.to(this.shader.uniforms.dark, 1.0, {value:0.45, ease:Quad.easeInOut});

        // this.shader.uniforms.dark.value = 0.45;
    }

    over(){
        // this.dark = (this.dark * _main._conf.s093a) + (this.darkMin * _main._conf.s093b);
        gsap.killTweensOf(this.shader.uniforms.dark);
        this.shader.uniforms.dark.value = 0.8;

        if( Math.abs( this.dark - this.darkMin ) < 0.002 )
        {
            this.dark = this.darkMin;
            this.shader.uniforms.dark.value = this.dark;
        }
    }

    resizeHandler(){
        this.sw = window.innerWidth;
        this.sh = window.innerHeight;

        let sw = this.sw / this.width;
        let sh = this.sh / this.height;

        if( sw > sh ){
            this.mesh.scale.x = sw;
            this.mesh.scale.y = sw;
        }else{
            this.mesh.scale.x = sh;
            this.mesh.scale.y = sh;
        }
    }
}
